import styles from "./TabPanel.module.scss";

interface ITabPanel {
  children: JSX.Element | JSX.Element[];
}
const TabPanel = ({ children }: ITabPanel) => {
  return <section className={styles.tabPanel}>{children}</section>;
};

export default TabPanel;
