import styles from "./Activities.module.scss";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { usePatientsActivityOccurrencesAndGroups } from "@/api/Activities";
import NoResults from "@/components/NoResults/NoResults";
import { useParentRoutesPatient } from "@/api/Patients";
import ActivityTable from "./ActivityTable";
import {
  addDays,
  endOfDay,
  isPast,
  min,
  subDays,
  differenceInCalendarDays,
} from "date-fns";
import { dateName } from "@/Utils/DateUtils";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { PlainButton } from "@components/Button/Button";
import { SplitPane } from "@/components/SplitPane/SplitPane";
import { deducedError } from "@/Utils/ErrorUtils";
import PlusIcon from "@components/icons/PlusIcon";
import { Heading } from "@components/Heading/Heading";
import type { IPatient } from "@models/patients";
import { activeStatuses } from "@models/patients";
import ArrowUpIcon from "@components/icons/ArrowUpIcon";
import { useState } from "react";
import * as Sentry from "@sentry/react";
import { format } from "@models/date-and-time";
import { t, Trans } from "@lingui/macro";

const DAYS_AHEAD_TO_SHOW_ACTIVITIES = 7;
// Enforced by backend
const MAXIMUM_ALLOWED_SPAN_DAYS = 28;

const canAddActivitiesToPatient = ({ status }: Pick<IPatient, "status">) =>
  activeStatuses.some((s) => s === status);

const Activities = () => {
  const patient = useParentRoutesPatient();

  const navigate = useNavigate();

  const location = useLocation();
  const isInActivities = location.pathname.endsWith("activities");

  const now = new Date();

  const [daysBackToShowActivities, setDaysBackToShowActivities] = useState(0);
  const startDate = subDays(now, daysBackToShowActivities);
  const endDate = min([
    addDays(now, DAYS_AHEAD_TO_SHOW_ACTIVITIES),
    addDays(subDays(now, daysBackToShowActivities), MAXIMUM_ALLOWED_SPAN_DAYS),
  ]);
  const {
    data: activityOccurrencesAndGroups,
    isPending,
    isError,
    error,
    isPlaceholderData,
  } = usePatientsActivityOccurrencesAndGroups(
    patient.id,
    format(startDate, "yyyy-MM-dd"),
    format(endDate, "yyyy-MM-dd"),
  );

  const displayedDays = Array.from(
    { length: differenceInCalendarDays(endDate, startDate) },
    (_, index) => addDays(subDays(now, daysBackToShowActivities), index),
  );

  if (isPending) {
    return (
      <Loading
        message={t`Laddar patientens aktivitetstillfällen`}
        padding={24}
      />
    );
  }

  if (isError) {
    Sentry.captureException(error);
    return <ErrorMessage message={deducedError(error)} padding={24} />;
  }

  const patientName = patient.name;
  const startDateName = dateName(startDate);
  const endDateName = dateName(endDate);
  return (
    <SplitPane>
      <div className={styles.activities}>
        <div className={styles.activitiesControls}>
          <PlainButton
            onClick={() => setDaysBackToShowActivities((prev) => prev + 1)}
            disabled={isPlaceholderData}
          >
            <ArrowUpIcon />
            <Trans>Visa föregående dag</Trans>
          </PlainButton>
          {canAddActivitiesToPatient(patient) && isInActivities ? (
            <PlainButton onClick={() => navigate("templates")}>
              <PlusIcon />
              <Trans>Ny aktivitet</Trans>
            </PlainButton>
          ) : undefined}
        </div>
        {activityOccurrencesAndGroups.length === 0 ? (
          <NoResults
            message={t`${patientName} har inga aktiviteter mellan ${startDateName} och ${endDateName}`}
          />
        ) : (
          <></>
        )}
        {activityOccurrencesAndGroups.length >= 1 ? (
          <ul className={styles.activityOccurrencesTableList}>
            {displayedDays.map((day, index) => {
              const nameOfDay = dateName(day);
              const activityOccurrencesOnDay =
                activityOccurrencesAndGroups.filter(
                  (activityOccurrencesAndGroups) =>
                    format(activityOccurrencesAndGroups.start, "yyyy-MM-dd") ===
                    format(day, "yyyy-MM-dd"),
                );

              // While fetching the previous day, we don't want to show the "no activities" message until we know.
              if (isPlaceholderData && index === 0) {
                return null;
              }

              // Only show "no activities" message for past days.
              if (
                activityOccurrencesOnDay.length === 0 &&
                isPast(endOfDay(day))
              ) {
                return (
                  <li key={format(day, "yyyy-MM-dd")}>
                    <NoResults
                      message={t`${patientName} hade inga aktiviteter ${nameOfDay}`}
                    />
                  </li>
                );
              }

              // For later days with no activities, don't show anything.
              if (activityOccurrencesOnDay.length === 0) {
                return null;
              }

              return (
                <li key={format(day, "yyyy-MM-dd")}>
                  <Heading
                    level="h2"
                    className={styles.dayHeading}
                  >{`${dateName(day)} (${
                    activityOccurrencesOnDay.length
                  })`}</Heading>
                  <ActivityTable
                    activityOccurrencesAndGroups={activityOccurrencesAndGroups.filter(
                      ({ start }) =>
                        format(start, "yyyy-MM-dd") ===
                        format(day, "yyyy-MM-dd"),
                    )}
                  />
                </li>
              );
            })}
          </ul>
        ) : (
          <></>
        )}
      </div>
      <Outlet />
    </SplitPane>
  );
};

export default Activities;
