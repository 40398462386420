import TextArea from "@/components/TextArea/TextArea";
import type { IAddActivityFormData } from "./AddActivityForm";
import { useFormContext } from "react-hook-form";
import { t } from "@lingui/macro";

export const DescriptionInput = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<IAddActivityFormData>();

  return (
    <TextArea
      label={t`Beskrivning (valfri)`}
      description={t`Visas inte för patienten`}
      errorMessage={errors.description?.message}
      {...register(`description`)}
    />
  );
};
