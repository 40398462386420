import { Link } from "@components/Link/Link";
import { useUnreadNotificationCounts } from "@chat/useUnreadNotificationCounts";
import styles from "./NotificationsCell.module.scss";
import { useQuery } from "@tanstack/react-query";
import { patientsWithUnhandledMeasurementsQueryOptions } from "@/api/Patients";
import UnreadChatMessagesIndicator from "@/components/IconWithIndicator/UnreadChatMessagesIndicator/UnreadChatMessagesIndicator";
import UnhandledMeasurementsIndicator from "@/components/IconWithIndicator/UnhandledMeasurementsIndicator/UnhandledMeasurementsIndicator";
import { useCenterBaseUrl } from "@/Utils/useCenterBaseUrl";
import ChatContext from "@chat/ChatContext";
import { useContext } from "react";

export const NotificationsCell = ({ patientId }: { patientId: string }) => {
  const centerBaseUrl = useCenterBaseUrl();
  const { chatEnabled } = useContext(ChatContext);
  const { data: patientsWithUnhandledMeasurements } = useQuery(
    patientsWithUnhandledMeasurementsQueryOptions,
  );
  const unreadChatNotificationsCount = useUnreadNotificationCounts({
    roomName: patientId,
  });
  const hasUnreadChatMessages =
    unreadChatNotificationsCount !== undefined &&
    unreadChatNotificationsCount > 0;
  const hasUnhandledMeasurements = patientsWithUnhandledMeasurements?.find(
    ({ id, unhandledMeasurementsCount }) =>
      id === patientId && unhandledMeasurementsCount > 0,
  )
    ? true
    : false;

  return (
    <div className={styles.root}>
      {chatEnabled ? (
        <Link to={`${centerBaseUrl}/patients/${patientId}/communication`}>
          <UnreadChatMessagesIndicator
            hasUnreadMessages={hasUnreadChatMessages}
            unreadMessagesCount={unreadChatNotificationsCount}
            passiveState="visible"
          />
        </Link>
      ) : (
        <></>
      )}
      <Link to={`${centerBaseUrl}/patients/${patientId}/measurements`}>
        <UnhandledMeasurementsIndicator
          hasUnhandledMeasurements={hasUnhandledMeasurements}
          passiveState="visible"
        />
      </Link>
    </div>
  );
};

export default NotificationsCell;
