import { Popover } from "@/components/Popover/Popover";
import DocumentWithTextIcon from "@components/icons/DocumentWithTextIcon";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export const ActivityDescriptionPopover = ({
  description,
}: {
  description: string;
}) => {
  const { _ } = useLingui();
  return (
    <Popover
      trigger={{
        icon: <DocumentWithTextIcon />,
        ariaLabel: _(msg`Visa beskrivning`),
      }}
    >
      {description}
    </Popover>
  );
};
