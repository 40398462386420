// https://docs.sentry.io/platforms/javascript/user-feedback/configuration/#bring-your-own-button

import MegaphoneIcon from "@components/icons/MegaphoneIcon";
import * as Sentry from "@sentry/react";
import styles from "./SentryFeedbackButton.module.scss";
import { t, Trans } from "@lingui/macro";

export default function SentryFeedbackButton() {
  const feedback = Sentry.feedbackIntegration({
    formTitle: t`Feedback / Felrapport`,
    nameLabel: t`Ditt namn`,
    namePlaceholder: "",
    showEmail: false,
    messageLabel: t`Kort beskrivning`,
    messagePlaceholder: "",
    showBranding: false,
    submitButtonLabel: t`Skicka`,
    cancelButtonLabel: t`Avbryt`,
    colorScheme: "light",
    themeLight: {
      submitBackground: "#0078D4",
      submitBackgroundHover: "#00328A",
      submitBorder: "transparent",
      inputOutlineFocus: "#CCC",
    },
    successMessageText: t`Tack! Vi kollar och återkopplar snart.`,
    enableScreenshot: false,
  });

  // Don't render custom feedback button if Feedback integration isn't installed
  if (!feedback) {
    return null;
  }

  return (
    <button
      type="button"
      className={styles.feedbackButton}
      onClick={async () => {
        const form = await feedback.createForm();
        form.appendToDom();
        form.open();
      }}
    >
      <MegaphoneIcon /> <Trans>Feedback / Felrapport</Trans>
    </button>
  );
}
