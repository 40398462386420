import { measurementColor } from "@/Utils/ColorUtils";
import type { IChip } from "./Chip";
import Chip from "./Chip";
import type { IMeasurementsType } from "@models/activities";
import { measurementsDictionary } from "@models/activities";
import { useLingui } from "@lingui/react";

const MeasurementChip = ({
  measurement,
  size,
}: {
  measurement: IMeasurementsType;
} & Pick<IChip, "size">) => {
  const { _ } = useLingui();
  return (
    <Chip
      color={measurementColor[measurement].colorName}
      background={"solid"}
      border={"none"}
      size={size}
      shape={"sharp"}
    >
      {_(measurementsDictionary[measurement])}
    </Chip>
  );
};

export default MeasurementChip;
