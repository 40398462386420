import Chip from "./Chip";
import RecurrenceIcon from "@components/icons/RecurrenceIcon";

type IRecurrenceChip =
  | {
      time: {
        label: string;
        sensitivityLabel: string;
      };
      recurrenceLabel: string;
    }
  | {
      recurrenceLabel: string;
    };

export const RecurrenceChip = (props: IRecurrenceChip) => {
  const showTimeSlotsInChip = "time" in props;
  const { recurrenceLabel } = props;

  if (showTimeSlotsInChip) {
    const { label, sensitivityLabel } = props.time;

    return (
      <Chip iconStart={<RecurrenceIcon />}>
        {`${label} (${sensitivityLabel}), ${recurrenceLabel}`}
      </Chip>
    );
  }

  return <Chip iconStart={<RecurrenceIcon />}>{recurrenceLabel}</Chip>;
};
