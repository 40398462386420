import {
  patientKeys,
  removeRelative,
  useParentRoutesPatient,
} from "@/api/Patients";
import { PlainButton } from "@components/Button/Button";
import { AddRelative } from "@/forms/AddRelative";
import { EditAddress } from "@/forms/EditAddress";
import { EditInformation } from "@/forms/EditInformation";
import { EditPhoneNumber } from "@/forms/EditPhoneNumber";
import {
  deducedError,
  displayErrorMessageAlert,
  isErrorWithKnownErrorCode,
  knownErrorCodeSchema,
} from "@/Utils/ErrorUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import styles from "./EditInformationWrappers.module.scss";
import { Heading } from "@components/Heading/Heading";
import { EditSafetyAlarmText } from "@/forms/EditSafetyAlarm";
import { t, Trans } from "@lingui/macro";

export const EditAddressWrapper = () => {
  const patient = useParentRoutesPatient();
  const navigate = useNavigate();

  return (
    <section className={styles.editFormContainer}>
      <Heading level="h2">
        <Trans>Ändra adressen</Trans>
      </Heading>
      <EditAddress
        patientId={patient.id}
        currentAddress={patient.address}
        onSuccess={() => navigate(-1)}
      />
    </section>
  );
};

export const EditPhoneNumberWrapper = () => {
  const patient = useParentRoutesPatient();
  const navigate = useNavigate();

  return (
    <section className={styles.editFormContainer}>
      <Heading level="h2">
        <Trans>Ändra telefonnumret</Trans>
      </Heading>
      <EditPhoneNumber
        patientId={patient.id}
        currentPhoneNumber={patient.phoneNumber}
        onSuccess={() => navigate(-1)}
      />
    </section>
  );
};

export const EditRelativesWrapper = () => {
  const patient = useParentRoutesPatient();
  const patientName = patient.name;
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { mutate: removeRelativeMutation, isPending: isRemovingRelative } =
    useMutation({
      mutationFn: ({
        relativeId,
        patientId,
      }: {
        relativeId: string;
        patientId: string;
      }) => removeRelative(patientId, relativeId),
      onError: (error, { patientId }) => {
        if (
          isErrorWithKnownErrorCode(error) &&
          error.response.data.code ===
            knownErrorCodeSchema.Values.RelativeMissing
        ) {
          return queryClient.invalidateQueries({
            queryKey: patientKeys.detail(patientId),
          });
        } else {
          displayErrorMessageAlert(
            `${t`Gick inte att ta bort anhörig.`} ${deducedError(error)}`,
          );
        }
      },
      onSuccess: (_, { patientId }) => {
        return queryClient.invalidateQueries({
          queryKey: patientKeys.detail(patientId),
        });
      },
    });

  return (
    <div className={styles.relatives}>
      {patient.relatives.length > 0 ? (
        <section className={styles.editFormContainer}>
          <Heading level="h2">
            <Trans>Ändra anhöriga</Trans>
          </Heading>
          <ul className={styles.list}>
            {patient?.relatives.map(
              ({ id, name, relation, isLivingTogether, phoneNumber }) => {
                const optionalLivesTogetherWithSegment = isLivingTogether
                  ? t`bor med ${patientName} och`
                  : "";
                return (
                  <li key={id}>
                    <p>
                      <Trans>
                        {name} ({relation}) {optionalLivesTogetherWithSegment}{" "}
                        nås på {phoneNumber}
                      </Trans>
                    </p>
                    <PlainButton
                      onClick={() =>
                        removeRelativeMutation({
                          patientId: patient.id,
                          relativeId: id,
                        })
                      }
                      disabled={isRemovingRelative}
                    >
                      <Trans>Ta bort</Trans>
                    </PlainButton>
                  </li>
                );
              },
            )}
          </ul>
        </section>
      ) : undefined}
      <section className={styles.editFormContainer}>
        <Heading level="h2">
          <Trans>Lägg till anhörig</Trans>
        </Heading>
        <AddRelative patientId={patient.id} onSuccess={() => navigate(-1)} />
      </section>
    </div>
  );
};

export const EditOtherInformationWrapper = () => {
  const patient = useParentRoutesPatient();
  const navigate = useNavigate();

  return (
    <section className={styles.editFormContainer}>
      <Heading level="h2">
        <Trans>Ändra allmän information</Trans>
      </Heading>
      <EditInformation
        patientId={patient.id}
        currentInformation={patient.information}
        onSuccess={() => navigate(-1)}
      />
    </section>
  );
};

export const EditSafetyAlarmWrapper = () => {
  const patient = useParentRoutesPatient();
  const navigate = useNavigate();

  return (
    <section className={styles.editFormContainer}>
      <Heading level="h2">
        <Trans>Ändra trygghetslarm</Trans>
      </Heading>
      <EditSafetyAlarmText
        patientId={patient.id}
        currentSafetyAlarmText={patient.safetyAlarmText}
        onSuccess={() => navigate(-1)}
      />
    </section>
  );
};
