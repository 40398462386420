import type { ChangeEvent } from "react";
import { forwardRef, useId } from "react";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import clsx from "clsx";
import styles from "./Checkbox.module.scss";

interface IStandaloneCheckbox {
  errorMessage?: string;
  label: string;
  name: string;
  checked?: boolean;
  disabled?: boolean;
  visualStyle?: "normal" | "framed";
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  checkboxPosition?: "center" | "top";
}

// CAUTION! This is built without proper care for accessibility, and should not be used carelessly.
const StandaloneCheckbox = forwardRef<
  HTMLInputElement | null,
  IStandaloneCheckbox
>(
  (
    {
      checked,
      disabled,
      errorMessage,
      label,
      name,
      visualStyle = "normal",
      onChange,
      checkboxPosition = visualStyle === "normal" ? "top" : "center",
      ...rest
    },
    ref,
  ) => {
    const id = useId();

    return (
      <div
        className={clsx(
          styles.checkboxWrapper,
          styles[`visualStyle-${visualStyle}`],
          styles[`checkboxPosition-${checkboxPosition}`],
        )}
      >
        <label htmlFor={id}>{label}</label>
        {errorMessage ? <ErrorMessage message={errorMessage} /> : undefined}
        <input
          id={id}
          type="checkbox"
          aria-invalid={errorMessage ? true : undefined}
          checked={checked}
          disabled={disabled}
          name={name}
          onChange={onChange}
          ref={ref}
          {...rest}
        />
      </div>
    );
  },
);
StandaloneCheckbox.displayName = "StandaloneCheckbox";

export default StandaloneCheckbox;
