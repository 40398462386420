import { PlainButton } from "@components/Button/Button";
import CrossIcon from "@components/icons/CrossIcon";
import { msg, t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

type AvailableAction =
  | "edit-time"
  | "edit-title"
  | "edit-description"
  | "edit-schedule"
  | "edit-requiredCompetences"
  | "edit-doubleStaffing"
  | "edit-hidden"
  | "edit-measurements"
  | "move";

const editableAttributesDictionary = {
  "edit-time": msg`tiden`,
  "edit-title": msg`namnet`,
  "edit-description": msg`beskrivningen`,
  "edit-schedule": msg`schemat`,
  "edit-requiredCompetences": msg`kompetenskraven`,
  "edit-doubleStaffing": msg`dubbelbemanning`,
  "edit-hidden": msg`dold för patienten`,
  "edit-measurements": msg`mätvärdena`,
  move: msg`tillhörande besök`,
};

export const EditButton = ({
  label = t`Ändra`,
  iconStart,
  attribute,
  isEditing,
  toggleIsEditing,
}: {
  label?: string;
  iconStart?: JSX.Element;
  attribute: AvailableAction;
  isEditing: boolean;
  toggleIsEditing: () => void;
}) => {
  const { _ } = useLingui();
  const currentlyEdited = _(editableAttributesDictionary[attribute]);
  return (
    <PlainButton
      size="small"
      weight="light"
      onClick={toggleIsEditing}
      aria-label={
        isEditing
          ? t`Avbryt ändring av ${currentlyEdited}`
          : t`Ändra ${currentlyEdited}`
      }
    >
      {isEditing ? (
        <>
          <CrossIcon />
          <Trans>Avbryt</Trans>
        </>
      ) : (
        <>
          {iconStart}
          {label}
        </>
      )}
    </PlainButton>
  );
};
