import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";
import { isUnauthorizedError } from "./Utils/ErrorUtils";
import { MsalProvider } from "@azure/msal-react";
import {
  publicClientApplication,
  roles,
  userManager,
  ENVIRONMENT,
} from "./Utils/EnvUtils";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import { Loading } from "@components/Loading/Loading";
import Login from "./pages/login/Login";
import { Login as IdsLogin } from "./pages/login/IdsLogin";
import StartPage from "./pages";
import CommandCenterContainer from "./pages/commandcenter/CommandCenterContainer";
import Patients from "./pages/commandcenter/Patients/Patients";
import Patient from "./pages/commandcenter/Patients/Patient/Patient";
import PatientActivities from "./pages/commandcenter/Patients/Patient/Activities/Activities";
import { Discharge } from "./pages/commandcenter/Patients/Patient/Discharge/Discharge";
import { Equipment } from "./pages/commandcenter/Patients/Patient/Equipment/Equipment";
import Information from "./pages/commandcenter/Patients/Patient/Information/Information";
import Routes from "./pages/ambulating/Routes/Routes";
import VisitDetails from "./pages/ambulating/Routes/$routeId/visits/$visitId";
import AmbulatingContainer from "./pages/ambulating/AmbulatingContainer";
import SingleActivityOccurrence from "./pages/commandcenter/Patients/Patient/Activities/ActivityOccurrence/SingleActivityOccurrence";
import RecurringActivitySeries from "./pages/commandcenter/Patients/Patient/Activities/ActivityOccurrence/RecurringActivitySeries";
import { AddActivity } from "./pages/commandcenter/Patients/Patient/Activities/AddActivity/AddActivity";
import { VideoMeeting as CenterVideo } from "./pages/commandcenter/VideoMeeting";
import { Patient as PatientGo } from "./pages/ambulating/patients/$patientId/Patient";
import { Information as InformationGo } from "./pages/ambulating/patients/$patientId/Information";
import {
  EditAddressWrapper,
  EditOtherInformationWrapper,
  EditPhoneNumberWrapper,
  EditRelativesWrapper,
  EditSafetyAlarmWrapper,
} from "./pages/ambulating/patients/$patientId/EditInformationWrappers";
import { Activities } from "@/pages/commandcenter/Activities";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthProvider } from "react-oidc-context";
import RequireIdsAuth from "./components/RequireAuth/RequireIdsAuth";
import { AddActivityDialog } from "./pages/commandcenter/Activities/AddActivityDialog/AddActivityDialog";
import { TabsView as GoTabsView } from "./pages/ambulating";
import { OtherActivities } from "./pages/ambulating/OtherActivities/OtherActivities";
import { Communication } from "./pages/commandcenter/Patients/Patient/Communication/Communication";
import { Routes as RoutesPage } from "@/pages/commandcenter/Routes/Routes";
import { RoutesList } from "@/pages/commandcenter/Routes/RoutesList";
import { Measurements } from "./pages/commandcenter/Patients/Patient/Measurements/Measurements";
import { ActivityDetailsDialog } from "./pages/commandcenter/Activities/ActivityDetailsDialog/ActivityDetailsDialog";
import { ProspectPatients } from "./pages/commandcenter/Patients/PatientsTable/ProspectPatients";
import { DischargedPatients } from "./pages/commandcenter/Patients/PatientsTable/DischargedPatients";
import { AdmittedPatients } from "./pages/commandcenter/Patients/PatientsTable/AdmittedPatients";
import { RegisterProspectPatient } from "./pages/commandcenter/Patients/RegisterProspectPatient/RegisterProspectPatient";
import { AdmitPatient } from "./pages/commandcenter/Patients/Patient/AdmitPatient";
import { AddActivityForm } from "./forms/AddActivityForm/AddActivityForm";
import { ActivityTemplates } from "./pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/ActivityTemplates";
import Chat from "./pages/ambulating/chat/Chat";
import CenterChat from "./pages/commandcenter/Chat/Chat";
import CenterChatWithGo from "./pages/commandcenter/Chat/ChatWithGo";
import Pushy from "pushy-sdk-web";
import * as Sentry from "@sentry/react";
import Shifts from "./pages/commandcenter/Shifts/Shifts";
import { PatientNote } from "./pages/commandcenter/Patients/Patient/PatientNote/PatientNote";
import { PatientsContainer } from "./pages/commandcenter/Patients/PatientsContainer";
import { GroupDetails } from "./pages/commandcenter/Patients/Patient/Activities/GroupDetails/GroupDetails";
import { GroupDetailsDialog } from "./pages/commandcenter/Activities/GroupDetailsDialog/GroupDetailsDialog";
import { AllPatients } from "./pages/commandcenter/Patients/PatientsTable/AllPatients";
import PatientActivity from "./pages/commandcenter/Patients/Patient/Activities/ActivityOccurrence/ActivityOccurrence";
import { i18n } from "@lingui/core";
import { msg, Trans } from "@lingui/macro";
import { I18nApp } from "./I18nApp";

Sentry.init({
  enabled: import.meta.env.PROD || import.meta.env.VITE_SENTRY === true,
  dsn: "https://168acba32934d682ced94c15895fa43a@o4506739699220480.ingest.sentry.io/4506846606524416",
  environment: ENVIRONMENT,
  integrations: [
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        // RegExp for `fetchVisit`, `fetchAmbulatingRoutes`, and `fetchRoute` used for debugging MED-2522, MED-2257
        /^.*logistics.*\/routes\/[a-fA-F\d]{8}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{12}\/visits\/[a-fA-F\d]{8}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{12}$/,
        /^.*logistics.*\/ambulating\/routes.*$/,
        /^.*logistics.*\/routes\/[a-fA-F\d]{8}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{12}$/,
      ],
    }),
  ],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (import.meta.env.DEV && import.meta.env.VITE_MSW) {
  const { worker } = await import("./mocks/browser");
  worker.start({
    onUnhandledRequest(req) {
      if (req?.url?.includes("medoma"))
        console.error(
          "Found an unhandled %s request to %s",
          req.method,
          req.url,
        );
    },
  });
  worker.listHandlers();
}

// Navigate on push notification click
Pushy.setNotificationListener((data) => {
  if (!JSON.stringify(data).includes("navigate")) return;
  if (!JSON.stringify(data).includes("_pushy")) return;
  if (data?.action === "navigate" && data.url) {
    router.navigate(new URL(data.url).pathname);
  }
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, error) => {
        return count < 3 && !isUnauthorizedError(error);
      },
    },
  },
});

const centerElement = (
  <RequireAuth acceptedRoles={[roles.Values.CommandCenter]}>
    <Suspense
      fallback={<Loading message={i18n._(msg`Laddar ledningscentralens vy`)} />}
    >
      <RequireIdsAuth>
        <CommandCenterContainer />
      </RequireIdsAuth>
    </Suspense>
  </RequireAuth>
);

const centerChildren = [
  {
    index: true,
    element: <Navigate to="patients" replace />,
  },
  {
    path: "patients",
    element: (
      <PatientsContainer>
        <Outlet />
      </PatientsContainer>
    ),
    children: [
      {
        element: <Patients />,
        children: [
          {
            index: true,
            element: <Navigate to="admitted" replace />,
          },
          {
            path: "admitted",
            element: <AdmittedPatients />,
          },
          {
            path: "discharged",
            element: <DischargedPatients />,
          },
          {
            path: "prospect",
            element: <ProspectPatients />,
          },
          {
            path: "all",
            element: <AllPatients />,
          },
        ],
      },
      {
        path: "new-prospect",
        element: <RegisterProspectPatient />,
      },
      {
        path: ":patientId",
        element: <Patient />,
        children: [
          {
            index: true,
            element: <Navigate to="activities" replace />,
          },
          {
            path: "admit",
            element: <AdmitPatient />,
          },
          {
            path: "activities",
            element: <PatientActivities />,
            children: [
              {
                path: ":activityId/occurrences/:occurrenceId",
                element: <PatientActivity />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="singleOccurrence" replace />,
                  },
                  {
                    path: "singleOccurrence",
                    element: <SingleActivityOccurrence />,
                  },
                  {
                    path: "recurringOccurrence",
                    element: <RecurringActivitySeries />,
                  },
                ],
              },
              {
                path: "templates",
                element: <ActivityTemplates />,
              },
              {
                path: "new",
                element: <AddActivity />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="custom" replace />,
                  },
                  {
                    path: "custom",
                    element: <AddActivityForm />,
                  },
                ],
              },
              {
                path: "occurrence-groups/:groupId",
                element: <GroupDetails />,
              },
            ],
          },
          {
            path: "information",
            element: <Information />,
          },
          {
            path: "communication",
            element: (
              <Communication />
              /* NOTE: If you plan to add more sub routes here, ensure that the alert logic still holds up.
                2023-06-28: const hasChatPanelOpen = pathname.endsWith("communication");
                */
            ),
          },
          {
            path: "discharge",
            element: <Discharge />,
          },
          {
            path: "patient-note",
            element: <PatientNote />,
          },
          {
            path: "measurements",
            element: <Measurements />,
          },
          {
            path: "equipment",
            element: <Equipment />,
          },
        ],
      },
    ],
  },
  {
    path: "activities",
    element: (
      <>
        <Activities />
        {/* CAVEAT: This works great for Dialog children, but will not work well for other potential children. */}
        <Outlet />
      </>
    ),
    children: [
      {
        path: "new",
        element: (
          <AddActivityDialog redirectPathRelativeToCenterRootOnClose="activities" />
        ),
      },
      {
        path: ":activityId/occurrences/:occurrenceId",
        element: (
          <ActivityDetailsDialog redirectPathRelativeToCenterRootOnClose="activities" />
        ),
        children: [
          {
            index: true,
            element: <Navigate to="singleOccurrence" replace />,
          },
          {
            path: "singleOccurrence",
            element: <SingleActivityOccurrence />,
          },
          {
            path: "recurringOccurrence",
            element: <RecurringActivitySeries />,
          },
        ],
      },

      {
        path: "occurrence-groups/:groupId",
        element: <GroupDetailsDialog />,
      },
    ],
    errorElement: (
      <div>
        <Trans>
          <h1>Något gick fel</h1>
          <p>Ladda om sidan</p>
        </Trans>
      </div>
    ),
  },
  {
    path: "routes",
    element: <RoutesPage />,
    children: [
      {
        index: true,
        element: <Navigate to="planned" replace />,
      },
      {
        path: "planned",
        element: <RoutesList status={"draft"} />,
      },
      {
        path: "ongoing",
        element: <RoutesList status={"ongoing"} />,
      },
      {
        path: "finished",
        element: <RoutesList status={"finished"} />,
      },
    ],
  },
  {
    path: "shifts",
    element: (
      <>
        <Shifts />
        {/* CAVEAT: This works great for Dialog children, but will not work well for other potential children. */}
        <Outlet />
      </>
    ),
    children: [
      {
        path: "activities/:activityId/occurrences/:occurrenceId",
        element: (
          <ActivityDetailsDialog redirectPathRelativeToCenterRootOnClose="shifts" />
        ),
        children: [
          {
            index: true,
            element: <Navigate to="singleOccurrence" replace />,
          },
          {
            path: "singleOccurrence",
            element: <SingleActivityOccurrence />,
          },
          {
            path: "recurringOccurrence",
            element: <RecurringActivitySeries />,
          },
        ],
      },
      {
        path: "activities/new",
        element: (
          <AddActivityDialog redirectPathRelativeToCenterRootOnClose="shifts" />
        ),
      },
    ],
  },
  {
    path: "chat",
    element: <CenterChat />,
    children: [
      {
        path: ":employeeId",
        element: <CenterChatWithGo />,
      },
    ],
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      // Used for Center by the main Center user, including chat.
      {
        path: "commandcenter",
        element: centerElement,
        children: centerChildren,
      },
      // Used for Center by other users, excluding chat.
      {
        path: "center",
        element: centerElement,
        children: centerChildren,
      },
      {
        path: "ambulating",
        element: (
          <RequireAuth acceptedRoles={[roles.Values.Ambulating]}>
            <Suspense
              fallback={
                <Loading message={i18n._(msg`Laddar ambulerande vy`)} />
              }
            >
              <AmbulatingContainer />
            </Suspense>
          </RequireAuth>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="routes" replace />,
          },
          {
            element: <GoTabsView />,
            children: [
              {
                index: true,
                element: <Navigate to="routes" replace />,
              },
              {
                path: "routes",
                children: [
                  {
                    index: true,
                    element: <Routes />,
                  },
                  {
                    path: ":routeId/visits/:visitId",
                    element: <VisitDetails />,
                  },
                ],
              },
              {
                path: "other-activities",
                element: <OtherActivities />,
              },
            ],
          },
          {
            path: "patients",
            children: [
              {
                path: ":patientId",
                element: <PatientGo />,
                children: [
                  {
                    index: true,
                    element: <InformationGo />,
                  },
                  {
                    path: "edit-address",
                    element: <EditAddressWrapper />,
                  },
                  {
                    path: "edit-phone-number",
                    element: <EditPhoneNumberWrapper />,
                  },
                  {
                    path: "edit-relatives",
                    element: <EditRelativesWrapper />,
                  },
                  {
                    path: "edit-other-information",
                    element: <EditOtherInformationWrapper />,
                  },
                  {
                    path: "edit-safety-alarm",
                    element: <EditSafetyAlarmWrapper />,
                  },
                ],
              },
            ],
          },
          {
            path: "chat",
            element: <Chat />,
          },
        ],
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "ids-login",
        element: <IdsLogin />,
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
      {
        index: true,
        element: <StartPage />,
      },
      {
        path: "video/:patientId",
        element: <CenterVideo />,
      },
      {
        path: "status",
        element: <p>OK</p>,
      },
    ],
  },
]);

publicClientApplication.initialize().then(() => {
  const root = createRoot(document.getElementById("root")!);
  root.render(
    <React.StrictMode>
      <MsalProvider instance={publicClientApplication}>
        <AuthProvider userManager={userManager}>
          <QueryClientProvider client={queryClient}>
            <HelmetProvider>
              <I18nApp>
                <RouterProvider
                  router={router}
                  future={{ v7_startTransition: true }}
                />
              </I18nApp>
            </HelmetProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </AuthProvider>
      </MsalProvider>
    </React.StrictMode>,
  );
});
