import { useCenterBaseUrl } from "@/Utils/useCenterBaseUrl";
import { useNavigateWithPreservedQueryParams } from "@/Utils/useNavigateWithPreservedQueryParams";
import { Dialog } from "@components/Dialog/Dialog";
import ActivityOccurrence from "@/pages/commandcenter/Patients/Patient/Activities/ActivityOccurrence/ActivityOccurrence";
import { t } from "@lingui/macro";

export const ActivityDetailsDialog = ({
  redirectPathRelativeToCenterRootOnClose,
}: {
  // onClose navigation is required so that ESC, click outside, and close button all actually close the dialog
  // BUT, we can't control any navigation from within the dialog, so we need to pass the redirect path to this Dialog
  // which in this case is the relative path to the center root
  redirectPathRelativeToCenterRootOnClose: string;
}) => {
  const { navigateWithPreservedQueryParams } =
    useNavigateWithPreservedQueryParams();
  const centerBaseUrl = useCenterBaseUrl();

  return (
    <Dialog
      isOpen={true}
      width="fixed"
      onClose={() => {
        navigateWithPreservedQueryParams(
          `${centerBaseUrl}/${redirectPathRelativeToCenterRootOnClose}`,
        );
      }}
      title={t`Aktivitetsdetaljer`}
      hideTitle
    >
      <ActivityOccurrence showCloseButton={false} />
    </Dialog>
  );
};
