import { logisticsApi } from "@/api/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { employee, employees } from "@models/shifts";
import { fetchUser } from "@/api/Users";
import { z } from "zod";

async function fetchEmployee(employeeId: string) {
  const employeeResponse = await logisticsApi.get(`/employees/${employeeId}`);
  const parsedEmployee = employee.parse(employeeResponse.data);
  return parsedEmployee;
}

async function fetchEmployees() {
  const employeesResponse = await logisticsApi.get("/employees");
  const parsedEmployees = employees.parse(employeesResponse.data);
  return parsedEmployees;
}

export const employeeKeys = {
  all: ["employees"] as const,
  lists: () => [...employeeKeys.all, "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...employeeKeys.lists(), { filters }] as const,
  detail: (id: string) => [...employeeKeys.all, id, "details"] as const,
};

export const fetchCurrentEmployee = async () => {
  const fetchUserResponse = await fetchUser();
  const parsedEmployeeId = z.string().parse(fetchUserResponse.employeeId);
  const employee = await fetchEmployee(parsedEmployeeId);
  return employee;
};

export const useCurrentEmployee = () => {
  return useQuery({
    queryKey: employeeKeys.detail("me"),
    queryFn: fetchCurrentEmployee,
  });
};

export const useEmployee = (employeeId: string) => {
  return useQuery({
    queryKey: employeeKeys.detail(employeeId),
    queryFn: () => fetchEmployee(employeeId),
  });
};

export const useEmployees = () => {
  return useQuery({
    queryKey: employeeKeys.lists(),
    queryFn: fetchEmployees,
  });
};
