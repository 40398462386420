import styles from "./PatientsContainer.module.scss";

/**
 * Visual container for the patients table, patient details view, and anything else under `/patients`.
 */
export const PatientsContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className={styles.patientsContainer}>{children}</div>;
};
