import styles from "./DetailsPopover.module.scss";
import {
  Dialog,
  DialogTrigger,
  Popover,
  type DialogProps,
  type PopoverProps,
} from "react-aria-components";

type IDetailsPopover = {
  trigger?: React.ReactNode;
  children: React.ReactNode;
  popoverProps?: PopoverProps;
  dialogProps?: DialogProps;
};

const PopoverDialog = ({
  children,
  popoverProps,
  dialogProps,
}: {
  children: React.ReactNode;
  popoverProps?: PopoverProps;
  dialogProps?: DialogProps;
}) => (
  <Popover {...popoverProps}>
    <Dialog className={styles.dialog} {...dialogProps}>
      {children}
    </Dialog>
  </Popover>
);

export const DetailsPopover = ({
  children,
  trigger,
  popoverProps,
  dialogProps,
}: IDetailsPopover) => {
  return trigger ? (
    <DialogTrigger>
      {trigger}
      <PopoverDialog popoverProps={popoverProps} dialogProps={dialogProps}>
        {children}
      </PopoverDialog>
    </DialogTrigger>
  ) : (
    <PopoverDialog popoverProps={popoverProps} dialogProps={dialogProps}>
      {children}
    </PopoverDialog>
  );
};
