import type {
  IActivityOccurrenceOrGroup,
  IActivityOccurrencesAndGroups,
  ITimeOfDay,
} from "@models/activities";
import { categorySchema, isGroup, timeOfDaySchema } from "@models/activities";
import type { IMedicalCompetence } from "@models/shifts";

const requiredCompetences = (
  activityOccurrenceOrGroup: IActivityOccurrenceOrGroup,
): IMedicalCompetence[] | undefined =>
  "requiredCompetences" in activityOccurrenceOrGroup
    ? activityOccurrenceOrGroup.requiredCompetences
    : undefined;

export const getDataFromActivityOccurrencesAndGroups = (
  activityOccurrencesAndGroups: IActivityOccurrencesAndGroups,
) => {
  return activityOccurrencesAndGroups.map((activityOccurrenceOrGroup) => {
    if (isGroup(activityOccurrenceOrGroup)) {
      const group = activityOccurrenceOrGroup;
      return {
        activityOccurrenceOrGroup: group,
        category: categorySchema.Values.HomeVisit,
        timespan: [
          group.start,
          group.end,
          group.occurrences.every(
            ({ timeOfDay }) => timeOfDay === timeOfDaySchema.Values.Any,
          )
            ? timeOfDaySchema.Values.Any
            : timeOfDaySchema.Values.Specific,
        ] as [Date, Date, ITimeOfDay],
        title: group,
        patient: group.occurrences[0]?.patient,
        actors: group,
        block: group,
        status: group.occurrences.map(({ status }) => status),
        requiredCompetences: requiredCompetences(group),
      };
    } else {
      const activityOccurrence = activityOccurrenceOrGroup;
      return {
        activityOccurrenceOrGroup: activityOccurrence,
        category: activityOccurrence.category,
        timespan: [
          activityOccurrence.start,
          activityOccurrence.end,
          activityOccurrence.timeOfDay,
        ] as [Date, Date, ITimeOfDay],
        title: activityOccurrence,
        patient: activityOccurrence.patient,
        actors: activityOccurrence,
        block: activityOccurrence,
        status: [activityOccurrence.status],
        requiredCompetences: requiredCompetences(activityOccurrence),
      };
    }
  });
};
