import { activityKeys, updateRequiredCompetences } from "@/api/Activities";
import { FilledButton } from "@components/Button/Button";
import Checkbox from "@/components/Checkbox/Checkbox";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import Form from "@/components/Form/Form";
import { Loading } from "@components/Loading/Loading";
import { deducedError } from "@/Utils/ErrorUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import type { IMedicalCompetence } from "@models/shifts";
import {
  medicalCompetenceDictionary,
  medicalCompetenceSchema,
} from "@models/shifts";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";

export const EditRequiredCompetences = ({
  currentRequiredCompetences,
  onSuccess,
  activityId,
}: {
  currentRequiredCompetences: IMedicalCompetence[];
  onSuccess: () => void;
  activityId: string;
}) => {
  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      requiredCompetences: currentRequiredCompetences,
    },
  });

  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (requiredCompetences: IMedicalCompetence[]) =>
      updateRequiredCompetences(activityId, requiredCompetences),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: activityKeys.all });
      onSuccess();
    },
  });

  const { _ } = useLingui();

  return (
    <Form
      onSubmit={handleSubmit((formData) => {
        mutate(formData.requiredCompetences);
      })}
    >
      {isPending || isSuccess ? (
        <Loading message={t`Ändrar kompetenskrav`} />
      ) : (
        <>
          {errors.root?.server?.message && !isDirty ? (
            <ErrorMessage message={errors.root.server.message} />
          ) : null}
          <Form.Row>
            <Checkboxes
              errorMessage={errors.requiredCompetences?.message}
              orientation="horizontal"
            >
              {/* Only one Checkbox has to hold additional logic, applies to whole registration. */}
              <Checkbox
                label={{
                  text: _(medicalCompetenceDictionary.NurseAssistant.short),
                }}
                {...register(`requiredCompetences`, {
                  required: {
                    value: true,
                    message: t`Minst en kompetens behöver anges`,
                  },
                })}
                value={medicalCompetenceSchema.Values.NurseAssistant}
              />
              <Checkbox
                label={{
                  text: _(medicalCompetenceDictionary.NursePractitioner.short),
                }}
                {...register(`requiredCompetences`)}
                value={medicalCompetenceSchema.Values.NursePractitioner}
              />
              <Checkbox
                label={{
                  text: _(medicalCompetenceDictionary.MedicalDoctor.short),
                }}
                {...register(`requiredCompetences`)}
                value={medicalCompetenceSchema.Values.MedicalDoctor}
              />
              <Checkbox
                label={{ text: _(medicalCompetenceDictionary.Support.long) }}
                {...register(`requiredCompetences`)}
                value={medicalCompetenceSchema.Values.Support}
              />
            </Checkboxes>
          </Form.Row>
          {isDirty ? (
            <FilledButton type="submit">
              <Trans>Spara ändringar</Trans>
            </FilledButton>
          ) : (
            <></>
          )}
        </>
      )}
    </Form>
  );
};
