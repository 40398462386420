import type { IMedicalCompetence } from "@models/shifts";
import type { IMeasurementsType } from "@models/activities";

export const competenceColor: Record<
  IMedicalCompetence,
  {
    colorName: "green" | "blue" | "red" | "orange" | "purple";
    primaryColor: string;
    backgroundColor: string;
  }
> = {
  NurseAssistant: {
    colorName: "green",
    primaryColor: "var(--color-green-40)",
    backgroundColor: "var(--color-green-container)",
  },
  NursePractitioner: {
    colorName: "blue",
    primaryColor: "var(--color-blue-40)",
    backgroundColor: "var(--color-blue-container)",
  },
  MedicalDoctor: {
    colorName: "red",
    primaryColor: "var(--color-red-40)",
    backgroundColor: "var(--color-red-container)",
  },
  Physiotherapist: {
    colorName: "orange",
    primaryColor: "var(--color-orange)",
    backgroundColor: "var(--color-orange-container)",
  },
  Support: {
    colorName: "purple",
    primaryColor: "var(--color-purple-40)",
    backgroundColor: "var(--color-purple-container)",
  },
};
export const measurementColor: Record<
  IMeasurementsType,
  {
    colorName: "green" | "blue" | "red" | "orange" | "purple";
    primaryColor: string;
    backgroundColor: string;
  }
> = {
  bloodPressure: {
    colorName: "red",
    primaryColor: "var(--color-red-40)",
    backgroundColor: "var(--color-red-container)",
  },
  bloodGlucose: {
    colorName: "green",
    primaryColor: "var(--color-green-40)",
    backgroundColor: "var(--color-green-container)",
  },
  pulse: {
    colorName: "red",
    primaryColor: "var(--color-red-40)",
    backgroundColor: "var(--color-red-container)",
  },
  saturation: {
    colorName: "purple",
    primaryColor: "var(--color-purple-40)",
    backgroundColor: "var(--color-purple-container)",
  },
  weight: {
    colorName: "blue",
    primaryColor: "var(--color-blue-40)",
    backgroundColor: "var(--color-blue-container)",
  },
  temperature: {
    colorName: "orange",
    primaryColor: "var(--color-orange)",
    backgroundColor: "var(--color-orange-container)",
  },
};
