import { activityKeys, updateHidden } from "@/api/Activities";
import { FilledButton } from "@components/Button/Button";
import Checkbox from "@/components/Checkbox/Checkbox";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import Form from "@/components/Form/Form";
import { Loading } from "@components/Loading/Loading";
import { deducedError } from "@/Utils/ErrorUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { t, Trans } from "@lingui/macro";

export const EditHidden = ({
  currentHidden,
  onSuccess,
  activityId,
}: {
  currentHidden: boolean;
  onSuccess: () => void;
  activityId: string;
}) => {
  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      hidden: currentHidden,
    },
  });

  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (hidden: boolean) => updateHidden(activityId, hidden),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: activityKeys.all });
      onSuccess();
    },
  });

  return (
    <Form
      onSubmit={handleSubmit((formData) => {
        mutate(formData.hidden);
      })}
    >
      {isPending || isSuccess ? (
        <Loading message={t`Ändrar synlighet`} />
      ) : (
        <>
          {errors.root?.server?.message && !isDirty ? (
            <ErrorMessage message={errors.root.server.message} />
          ) : null}
          <Form.Row>
            <Checkbox
              label={{ text: t`Dölj aktivitet för patienten` }}
              {...register("hidden")}
            />
          </Form.Row>
          {isDirty ? (
            <FilledButton type="submit">
              <Trans>Spara ändringar</Trans>
            </FilledButton>
          ) : (
            <></>
          )}
        </>
      )}
    </Form>
  );
};
