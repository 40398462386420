import { useLocalStorage } from "@/Utils/useLocalStorage";

export const useShiftsViewToolbar = () => {
  const [storedExpandedValue, setStoredExpandedValue] = useLocalStorage(
    "shiftsViewExpanded",
    "false",
  );
  const [storedMapShownValue, setStoredMapShownValue] = useLocalStorage(
    "shiftsViewMapShown",
    "false",
  );
  const [storedToPlanShownValue, setStoredToPlanShownValue] = useLocalStorage(
    "shiftsViewToPlanShown",
    "true",
  );

  const isExpanded = storedExpandedValue === "true";

  const setIsExpanded = (value: boolean) => {
    setStoredExpandedValue(value ? "true" : "false");
  };

  const isMapShown = storedMapShownValue === "true";

  const setMapShown = (value: boolean) => {
    setStoredMapShownValue(value ? "true" : "false");
  };

  const isToPlanShown = storedToPlanShownValue === "true";

  const setToPlanShown = (value: boolean) => {
    setStoredToPlanShownValue(value ? "true" : "false");
  };

  return [
    isExpanded,
    setIsExpanded,
    isMapShown,
    setMapShown,
    isToPlanShown,
    setToPlanShown,
  ] as const;
};
