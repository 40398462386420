import Chip from "./Chip";
import CrossIcon from "@components/icons/CrossIcon";

type IBlockChip = {
  label: string;
  onRemove?: () => void;
  disabled?: boolean;
};

export const BlockChip = ({ label, onRemove, disabled }: IBlockChip) => {
  if (onRemove) {
    return (
      <Chip
        onClick={onRemove}
        shape="sharp"
        color="gray"
        size="small"
        iconEnd={<CrossIcon />}
        disabled={disabled}
      >
        {label}
      </Chip>
    );
  }
  return (
    <Chip shape="sharp" color="gray" size="small">
      {label}
    </Chip>
  );
};
