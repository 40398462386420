/* eslint-disable @typescript-eslint/no-unused-vars */
// We have a pattern to use types based on zod values in these files.

import { deduplicatePrimitiveArray } from "@/Utils/arrayUtils";
import { dateSchema } from "@models/date-and-time";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { logisticsApi, mapboxApi } from "./ApiClient";
import { generateQueryString } from "./Helpers";
import {
  createDeletedPatient,
  fetchPatient,
  fetchPatients,
  resolvePatient,
  type IListExistingPatient,
} from "./Patients";
import {
  visitSchemaWithPatientId,
  visitSchema,
  type IVisit,
  type IVisitWithPatientId,
} from "@models/visits";
import type { ICoordinatePair } from "@/components/Map/Map";
import { HOSPITAL_COORDINATES } from "@/Utils/EnvUtils";
import { timeOfDayDictionary, timeOfDaySchema } from "@models/activities";
import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { patientStatusSchema, type IPatient } from "@models/patients";

export const routeStatusSchema = z.enum(["draft", "ongoing", "finished"]);
export type IRouteStatus = z.infer<typeof routeStatusSchema>;
export const routeSchemaWithPatientId = z.object({
  id: z.string(),
  name: z.string(),
  date: z.string(),
  finishedAt: dateSchema.nullish(),
  status: routeStatusSchema,
  visits: z.array(visitSchemaWithPatientId),
  timespan: z
    .object({
      from: dateSchema,
      to: dateSchema,
    })
    .nullish(),
});
export type IRouteWithPatientId = z.infer<typeof routeSchemaWithPatientId>;

export const routeSchema = routeSchemaWithPatientId
  .omit({ visits: true })
  .extend({ visits: z.array(visitSchema) });
export type IRoute = z.infer<typeof routeSchema>;

export const optionallyNamedRouteWithPatientIdSchema = routeSchemaWithPatientId
  .omit({ name: true })
  .extend({ name: z.string().nullish() });
const optionallyNamedRoutesWithPatientIdsSchema = z.array(
  optionallyNamedRouteWithPatientIdSchema,
);
export const optionallyNamedRouteSchema = routeSchema
  .omit({ name: true })
  .extend({ name: z.string().nullish() });
export const optionallyNamedRoutesSchema = z.array(optionallyNamedRouteSchema);

const newRouteWithNameSchema = routeSchema
  .omit({
    visits: true,
    date: true,
    status: true,
    finishedAt: true,
  })
  .extend({
    date: z.string().nullish(),
  });
export type INewRouteWithName = z.infer<typeof newRouteWithNameSchema>;

const newRouteSchema = newRouteWithNameSchema.omit({
  name: true,
});
export type INewRoute = z.infer<typeof newRouteSchema>;

export const routesSchema = z.array(routeSchema);

export async function addRoute(newRoute: INewRoute) {
  await logisticsApi.post("/routes", newRoute);
}

export async function finishRoute(routeId: string) {
  await logisticsApi.post(`/routes/${routeId}/finish`);
}

export async function removeRoute(routeId: string) {
  await logisticsApi.delete(`/routes/${routeId}`);
}

export const timeOfDay = (time: "Any" | Date) => {
  if (time === timeOfDaySchema.Values.Any) {
    return i18n._(timeOfDayDictionary.Any.short);
  }
  const hour = time.getHours();

  if (hour >= 4 && hour < 10) {
    return t`Morgon`;
  }

  if (hour >= 10 && hour < 12) {
    return t`Förmiddag`;
  }

  if (hour >= 12 && hour < 17) {
    return t`Eftermiddag`;
  }

  if (hour >= 17 && hour <= 22) {
    return t`Kväll`;
  }

  if (hour >= 23 || (hour >= 0 && hour < 4)) {
    return t`Natt`;
  }

  return t`[tid på dagen]`;
};

export const generateRouteName = (
  route: z.infer<typeof optionallyNamedRouteSchema>,
) => {
  const { visits } = route;
  const occurrences = visits.flatMap((visit) => visit.occurrences);
  if (visits.length === 0) {
    return t`Ny rutt`;
  }
  if (!occurrences[0]) {
    return t`Ny rutt`;
  }
  const specificTimeOccurrences = occurrences.filter(
    (occurrence) => occurrence.timeOfDay === "Specific",
  );
  if (!specificTimeOccurrences[0]) {
    return i18n._(timeOfDayDictionary.Any.short);
  }
  return timeOfDay(
    specificTimeOccurrences[0].timeOfDay === timeOfDaySchema.Values.Any
      ? timeOfDaySchema.Values.Any
      : specificTimeOccurrences[0].start,
  );
};

const nameRoutes = (
  possiblyNamelessRoutes: z.infer<typeof optionallyNamedRoutesSchema>,
) => {
  return possiblyNamelessRoutes.map((route) => nameRoute(route));
};

const nameRoute = (
  possiblyNamelessRoute: z.infer<typeof optionallyNamedRouteSchema>,
) => {
  let { name } = possiblyNamelessRoute;
  if (!name) {
    name = generateRouteName(possiblyNamelessRoute);
  }
  return {
    ...possiblyNamelessRoute,
    name,
  };
};

const possiblyNamelessRoutesWithPatientIdsSchema = z.array(
  routeSchemaWithPatientId.omit({ name: true }),
);
const patientIdsInRoutes = (
  routesWithPatientIds: z.infer<
    typeof possiblyNamelessRoutesWithPatientIdsSchema
  >,
) =>
  deduplicatePrimitiveArray(
    routesWithPatientIds
      .flatMap(({ visits }) => visits)
      .flatMap(({ patientId }) => patientId),
  );

export const parseAndEnrichRoutes = async (probableRoutes: unknown) => {
  const possiblyNamelessRoutesWithPatientIds =
    optionallyNamedRoutesWithPatientIdsSchema.parse(probableRoutes);

  if (possiblyNamelessRoutesWithPatientIds.length === 0) {
    return possiblyNamelessRoutesWithPatientIds as [];
  }

  const patientIds = patientIdsInRoutes(possiblyNamelessRoutesWithPatientIds);
  const patients = await fetchPatients({ patientIds });
  const possiblyNamelessRoutes = optionallyNamedRoutesSchema.parse(
    possiblyNamelessRoutesWithPatientIds.map((route) => ({
      ...route,
      visits: route.visits.map((visit) => ({
        ...visit,
        patient: resolvePatient({ patientId: visit.patientId, patients }),
      })),
    })),
  );
  const namedRoutes = nameRoutes(possiblyNamelessRoutes);
  return namedRoutes;
};

export async function fetchRoutes({
  date,
  status,
}: {
  date: string;
  status?: IRouteStatus;
}) {
  const queryString = generateQueryString({
    date,
    status,
  });
  const routesResponse = await logisticsApi.get(`/routes${queryString}`);
  const parsedRoutes = await parseAndEnrichRoutes(routesResponse.data);
  return parsedRoutes;
}

export const routeKeys = {
  all: ["routes"] as const,
  lists: () => [...routeKeys.all, "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...routeKeys.lists(), { filters }] as const,
  detail: (id: string) => [...routeKeys.all, id, "details"] as const,
};

export const VISIT_KEY_ROOT = "visits";
export const visitKeys = (routeId: string) => ({
  all: () => [...routeKeys.all, routeId, VISIT_KEY_ROOT] as const,
  lists: () => [...visitKeys(routeId).all(), "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...visitKeys(routeId).lists(), { filters }] as const,
  detail: (id: string) => [...visitKeys(routeId).all(), id, "details"] as const,
});

export const useRoute = (routeId: string) => {
  return useQuery({
    queryKey: routeKeys.detail(routeId),
    queryFn: () => fetchRoute(routeId),
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
};

export const useRoutes = ({
  date,
  status,
}: {
  date: string;
  status?: IRouteStatus;
}) => {
  return useQuery({
    queryKey: routeKeys.list({ date, status }),
    queryFn: () => fetchRoutes({ date, status }),
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
};

export async function fetchRoute(routeId: string) {
  const routeResponse = await logisticsApi.get(`/routes/${routeId}`);
  const parsedRoute = optionallyNamedRouteWithPatientIdSchema.parse(
    routeResponse.data,
  );
  const patientIds = patientIdsInRoutes([parsedRoute]);
  const patients = await fetchPatients({ patientIds });
  const possiblyNamelessRoute = optionallyNamedRouteSchema.parse({
    ...parsedRoute,
    visits: parsedRoute.visits.map((visit) => ({
      ...visit,
      patient: resolvePatient({ patientId: visit.patientId, patients }),
    })),
  });
  const namedRoute = nameRoute(possiblyNamelessRoute);
  const route = namedRoute;

  return route;
}

export const useUpcomingVisit = (routeId: string) => {
  return useQuery({
    // It doesn't understand that the string is the routeId
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: visitKeys(routeId).list({ status: "upcoming" }),
    queryFn: () =>
      fetchRoute(routeId).then((route) => {
        const ongoingVisits = route.visits.filter(
          ({ status }) => status === "travellingTo" || status === "ongoing",
        );
        return ongoingVisits.length === 0
          ? (route.visits.find((visit) => visit.status === "planned") ?? null)
          : null;
      }),
  });
};

export const useOngoingVisit = (routeId: string) => {
  return useQuery({
    // It doesn't understand that the string is the routeId
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: visitKeys(routeId).list({ status: "ongoing" }),
    queryFn: () => {
      return fetchRoute(routeId).then((route) => {
        return (
          route.visits.find(
            ({ status }) => status === "travellingTo" || status === "ongoing",
          ) ?? null
        );
      });
    },
  });
};

const enrichVisit = ({
  visit,
  patient,
  routeId,
}: {
  visit: IVisitWithPatientId;
  patient: IPatient;
  routeId: string;
}) => {
  const visitWithPatient = { ...visit, patient };
  const visitWithRouteReference = { ...visitWithPatient, routeId: routeId };
  return visitWithRouteReference;
};

async function fetchVisit(routeId: string, visitId: string) {
  const visitResponse = await logisticsApi.get(
    `/routes/${routeId}/visits/${visitId}`,
  );
  const parsedVisit = visitSchemaWithPatientId.parse(visitResponse.data);
  try {
    const patient = await fetchPatient(parsedVisit.patientId);
    return enrichVisit({ visit: parsedVisit, patient, routeId });
  } catch (e) {
    const patient = createDeletedPatient(parsedVisit.patientId);
    return enrichVisit({ visit: parsedVisit, patient, routeId });
  }
}

export const useVisit = (routeId: string, visitId: string) => {
  return useQuery({
    // It doesn't understand that the string is the routeId
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: visitKeys(routeId).detail(visitId),
    queryFn: () => fetchVisit(routeId, visitId),
  });
};

export const getVisitsCoordinates = (visits: IVisit[]) => {
  return (
    visits.filter(
      ({ patient: { status } }) =>
        status !== patientStatusSchema.Values.deleted,
    ) as ReadonlyArray<{
      patient: IListExistingPatient;
    }>
  ).map(({ patient }) => {
    const {
      address: { coordinates },
    } = patient;

    return {
      longitude: coordinates.longitude,
      latitude: coordinates.latitude,
    };
  });
};

export async function fetchEmployeesRoutes(date: string) {
  const queryString = generateQueryString({
    date,
  });
  const routesResponse = await logisticsApi.get(
    `/ambulating/routes${queryString}`,
  );
  const parsedRoutes = await parseAndEnrichRoutes(routesResponse.data);
  return parsedRoutes;
}

export async function assignActivityOccurrenceToRoute(
  activityId: string,
  occurrenceId: string,
  routeId: string,
  visitId?: string,
  standalone?: boolean,
) {
  await logisticsApi.post(`/routes/${routeId}/assign`, {
    activityId,
    occurrenceId,
    behavior: {
      visitId,
      standalone,
    },
  });
}

export async function assignGroupToRoute(groupId: string, routeId: string) {
  await logisticsApi.post(`/routes/${routeId}/assign`, {
    occurrenceGroupId: groupId,
  });
}

export async function startTravellingToVisit(routeId: string, visitId: string) {
  await logisticsApi.post(
    `/routes/${routeId}/visits/${visitId}/startTravellingTo`,
  );
}

export async function startVisit(routeId: string, visitId: string) {
  await logisticsApi.post(`/routes/${routeId}/visits/${visitId}/start`);
}

export async function finishVisit(routeId: string, visitId: string) {
  await logisticsApi.post(`/routes/${routeId}/visits/${visitId}/finish`);
}

export async function moveToNewVisitInRoute(
  activityId: string,
  occurrenceId: string,
  routeId: string,
) {
  await logisticsApi.post(`/routes/${routeId}/separate`, {
    activityId,
    occurrenceId,
  });
}

export async function unassignActivityOccurrenceFromRoute(
  activityId: string,
  occurrenceId: string,
  routeId: string,
) {
  await logisticsApi.post(`/routes/${routeId}/unassign`, {
    activityId,
    occurrenceId,
  });
}

export async function unassignGroupFromRoute(groupId: string, routeId: string) {
  await logisticsApi.post(`/routes/${routeId}/unassign`, {
    occurrenceGroupId: groupId,
  });
}

export async function updateActivityOccurrencesOrderInRoute(
  orderedOccurrenceIds: string[],
  routeId: string,
) {
  await logisticsApi.post(`/routes/${routeId}/sort`, orderedOccurrenceIds);
}

export async function setRouteName(routeId: string, newName: string) {
  await logisticsApi.post(`/routes/${routeId}/set-name`, {
    name: newName,
  });
}

export const assignedShifts = (route: IRoute) => {
  const assignedShiftsWithDuplicates = route.visits.flatMap(
    ({ assignees }) => assignees,
  );
  const assignedShiftIdsWithDuplicates = assignedShiftsWithDuplicates.map(
    ({ id }) => id,
  );

  return assignedShiftsWithDuplicates.filter(
    (shift, index) =>
      assignedShiftIdsWithDuplicates.indexOf(shift.id) === index,
  );
};

export const cancelTravellingToVisit = async (
  routeId: string,
  visitId: string,
) => {
  await logisticsApi.post(
    `/routes/${routeId}/visits/${visitId}/revertTravellingTo`,
  );
};

export const getRouteFromActivityOccurrence = (
  routes: IRoute[],
  activityId: string,
  activityOccurrenceId: string,
) => {
  return routes.find((route) =>
    route.visits.find((visit) =>
      visit.occurrences.find(
        (occurrence) =>
          occurrence.id === activityOccurrenceId &&
          occurrence.activityId === activityId,
      ),
    ),
  );
};

export const getRouteFromGroup = (routes: IRoute[], groupId: string) => {
  return routes.find((route) =>
    route.visits.find((visit) => visit.id === groupId),
  );
};

type ISingleRouteCoordinates = ICoordinatePair[];
type IMultipleRoutesCoordinates = ISingleRouteCoordinates[];

const coordinatePairSchema = z.tuple([z.number(), z.number()]);

const mapboxRouteSchema = z.object({
  waypoints: z.array(
    z.object({
      location: coordinatePairSchema,
    }),
  ),
  legs: z.array(
    z.object({
      duration: z.number(),
    }),
  ),
  geometry: z.object({
    coordinates: z.array(coordinatePairSchema),
  }),
});

const mapboxDirectionsSchema = z.object({
  routes: z.array(mapboxRouteSchema),
});

export async function fetchDirections({
  multipleRoutesCoordinates,
}: {
  multipleRoutesCoordinates: IMultipleRoutesCoordinates;
}) {
  const STARTING_POINT = HOSPITAL_COORDINATES;
  const ENDING_POINT = HOSPITAL_COORDINATES;
  const encodedMultipleRoutesCoordinates = multipleRoutesCoordinates.map(
    (singleRouteCoordinates) =>
      [STARTING_POINT, ...singleRouteCoordinates, ENDING_POINT]
        .map(({ longitude, latitude }) => `${longitude},${latitude}`)
        .join(";"),
  );

  const encodedURLs = encodedMultipleRoutesCoordinates.map(
    (encodedSingleRouteCoordinates) =>
      encodeURI(
        `/directions/v5/mapbox/driving/${encodedSingleRouteCoordinates}?geometries=geojson&waypoints_per_route=true`,
      ),
  );

  const multipleRoutesDirectionsResponse = await Promise.all(
    encodedURLs.map(async (encodedURL) => mapboxApi.get(encodedURL)),
  );

  return multipleRoutesDirectionsResponse.map(
    (singleRouteDirectionsResponse) => {
      const singleRouteDirections = mapboxDirectionsSchema.parse(
        singleRouteDirectionsResponse.data,
      );

      // No suggestions? Probably not drivable
      if (!singleRouteDirections.routes[0]) {
        return null;
      }

      // https://docs.mapbox.com/api/navigation/directions/#response-retrieve-directions
      // An array of route objects ordered by descending recommendation rank. The response object may contain at most two routes.
      const recommendedRoute = singleRouteDirections.routes[0];
      const {
        geometry: { coordinates },
        legs,
      } = recommendedRoute;

      return {
        coordinates,
        legDurations: legs.map((leg) => Math.round(leg.duration / 60)),
      };
    },
  );
}

export const useDirections = (
  multipleRoutesCoordinates: IMultipleRoutesCoordinates,
) => {
  return useQuery({
    queryKey: routeKeys.list({
      coordinates: multipleRoutesCoordinates,
    }),
    queryFn: () => fetchDirections({ multipleRoutesCoordinates }),
    enabled: multipleRoutesCoordinates.length >= 1,
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
};
