import { Tabs, Tab, TabList, TabPanel } from "@components/Tabs";
import { Outlet, useLocation } from "react-router-dom";
import { RoutesContext } from "./RoutesContext";
import type { IRouteStatus } from "@/api/Routes";
import { useRoutes } from "@/api/Routes";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { useContext } from "react";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

export const RoutesTabs = () => {
  const { _ } = useLingui();
  const { search } = useLocation();
  const { setSelectedRouteId } = useContext(RoutesContext);
  const numberPlannedRoutes = useCountRoutes("draft");
  const numberOngoingRoutes = useCountRoutes("ongoing");
  const numberFinishedRoutes = useCountRoutes("finished");

  return (
    <Tabs>
      <TabList>
        <Tab
          // Include search params in the URL to keep the selected date when switching tabs
          to={`planned${search}`}
          onClick={() => setSelectedRouteId(undefined)}
        >
          {numberPlannedRoutes !== undefined
            ? _(msg`Planerade (${numberPlannedRoutes})`)
            : _(msg`Planerade`)}
        </Tab>
        <Tab
          to={`ongoing${search}`}
          onClick={() => setSelectedRouteId(undefined)}
        >
          {numberOngoingRoutes !== undefined
            ? _(msg`Pågående (${numberOngoingRoutes})`)
            : _(msg`Pågående`)}
        </Tab>
        <Tab
          to={`finished${search}`}
          onClick={() => setSelectedRouteId(undefined)}
        >
          {numberFinishedRoutes !== undefined
            ? _(msg`Avslutade (${numberFinishedRoutes})`)
            : _(msg`Avslutade`)}
        </Tab>
      </TabList>
      <TabPanel>
        <Outlet />
      </TabPanel>
    </Tabs>
  );
};

const useCountRoutes = (status: IRouteStatus) => {
  const selectedDate = useSelectedDate();
  const {
    data: allRoutes,
    isPending,
    isError,
  } = useRoutes({ date: selectedDate });

  if (isPending) return undefined;
  if (isError) return "-";

  return allRoutes.filter((route) => route.status === status).length.toString();
};
