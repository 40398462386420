import type { ITitleInputFields } from "@/forms/AddActivityForm/TitleInput";
import { TitleInput } from "@/forms/AddActivityForm/TitleInput";
import { titleSuggestions } from "@/forms/AddActivityForm/titleSuggestions";
import { FilledButton, PlainButton } from "@components/Button/Button";
import { FormProvider, useForm } from "react-hook-form";
import styles from "./QuickActivityInDetails.module.scss";
import { Loading, LoadingOverlay } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { deducedError } from "@/Utils/ErrorUtils";
import { getPatientNameWithStatus } from "@/api/Patients";
import type { ICompetencePickerFields } from "@/forms/AddActivityForm/CompetencePicker";
import { CompetencePicker } from "@/forms/AddActivityForm/CompetencePicker";
import { Heading } from "@components/Heading/Heading";
import { generateRandomUUID } from "@/Utils/UniqueId";
import Form from "@/components/Form/Form";
import type { INewActivityInGroup } from "@/api/Activities";
import { addActivityInGroup, useHomeVisitGroup } from "@/api/Activities";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import {
  categorySchema,
  timeOfDayDictionary,
  visitStatusSchema,
} from "@models/activities";
import { dateName } from "@/Utils/DateUtils";
import { CategoryIcon } from "@/components/CategoryIcon/CategoryIcon";
import Chip from "@/components/Chips/Chip";
import { AssignedShiftChip } from "@/components/Chips/AssignedShiftChip";
import { flushSync } from "react-dom";
import CrossIcon from "@components/icons/CrossIcon";
import PlusIcon from "@components/icons/PlusIcon";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import type { IVisitStatus } from "@models/visits";

const GROUP_DATE_SUFFIX_LENGTH = 8;
export const QuickActivityInDetails = ({
  groupId,
  visitStatus,
}: {
  groupId: string;
  visitStatus: IVisitStatus;
}) => {
  const { _ } = useLingui();
  const [open, setOpen] = useState(false);

  const { data: group, isPending, isError, error } = useHomeVisitGroup(groupId);

  const methods = useForm<ITitleInputFields & ICompetencePickerFields>();
  const {
    setError,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = methods;

  const queryClient = useQueryClient();
  const { mutate: addNewActivity, isPending: isAdding } = useMutation({
    mutationFn: ({ newActivity }: { newActivity: INewActivityInGroup }) =>
      addActivityInGroup(newActivity),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries();
      // Use `flushSync` to avoid flicker where `onSuccess` is done, while `setOpen` is not quite done.
      flushSync(() => {
        setOpen(false);
      });
      reset();
    },
  });

  const activityOccurrenceIsAddable = () => {
    return (
      (visitStatus !== visitStatusSchema.Values.ongoing &&
        visitStatus !== visitStatusSchema.Values.travellingTo) ||
      window.confirm(
        t`Är du säker på att du vill lägga till en aktivitet i ett pågående besök?`,
      )
    );
  };

  const validateAndSubmit = methods.handleSubmit((validatedFormData) => {
    const { requiredCompetences, title } = validatedFormData;

    const newActivity: INewActivityInGroup = {
      activityId: generateRandomUUID(),
      groupId,
      requiredCompetences,
      title,
    };

    return activityOccurrenceIsAddable()
      ? addNewActivity({ newActivity })
      : null;
  });

  const dateString = groupId.substring(
    groupId.length - GROUP_DATE_SUFFIX_LENGTH,
  );
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);
  const date = isNaN(new Date(`${year}-${month}-${day}T00:00:00`).getTime())
    ? null
    : new Date(`${year}-${month}-${day}T00:00:00`);

  return (
    <>
      {open ? (
        <>
          <PlainButton
            size="small"
            weight="light"
            onClick={() => setOpen(false)}
          >
            <CrossIcon />
            <Trans>Avbryt</Trans>
          </PlainButton>
          <div className={styles.container}>
            <FormProvider {...methods}>
              <LoadingOverlay
                show={isAdding}
                message={t`Lägger till aktiviteten`}
              >
                <Form onSubmit={validateAndSubmit}>
                  {isPending ? (
                    <Loading message={t`Laddar`} />
                  ) : isError ? (
                    <ErrorMessage
                      message={`${t`Kunde inte hämta information om besöket.`} ${deducedError(error)}`}
                    />
                  ) : (
                    <>
                      {errors.root?.server?.message && !isDirty ? (
                        <ErrorMessage message={errors.root.server.message} />
                      ) : undefined}
                      <TitleInput
                        suggestions={titleSuggestions.HomeVisit.map(
                          (untranslatedSuggestion) => _(untranslatedSuggestion),
                        )}
                      />
                      <CompetencePicker isRequired />
                      <div>
                        <Heading level="h2" size="h3" weight="medium">
                          <Trans>Övrigt</Trans>
                        </Heading>
                        <div className={styles.additionalConfig}>
                          <CategoryIcon
                            category={categorySchema.Values.HomeVisit}
                            size="small"
                          />
                          <Chip>{getPatientNameWithStatus(group.patient)}</Chip>
                          {date ? <Chip>{dateName(date)}</Chip> : null}
                          <Chip>{_(timeOfDayDictionary.Any.long)}</Chip>
                          {group.assignees?.map((assignee) => (
                            <AssignedShiftChip
                              key={assignee.id}
                              medicalCompetence={assignee.competence}
                              shift={assignee}
                            />
                          ))}
                        </div>
                      </div>
                      <FilledButton disabled={isAdding} type="submit">
                        <Trans>Skapa aktiviteten</Trans>
                      </FilledButton>
                    </>
                  )}
                </Form>
              </LoadingOverlay>
            </FormProvider>
          </div>
        </>
      ) : (
        <PlainButton size="small" weight="light" onClick={() => setOpen(true)}>
          <PlusIcon />
          <Trans>Ny aktivitet</Trans>
        </PlainButton>
      )}
    </>
  );
};
