import { useSearchParams } from "react-router-dom";
import { startOfDay } from "date-fns";

export const useSelectedDateHasPassed = () => {
  const now = new Date();
  const [searchParams] = useSearchParams();
  const selectedDate = new Date(searchParams.get("day") ?? now);
  const hasPassed = startOfDay(selectedDate) < startOfDay(now);
  return hasPassed;
};
