import { createContext, useState, useContext } from "react";

export const RoutesContext = createContext<{
  selectedRouteId: string | undefined;
  setSelectedRouteId: (routeId: string | undefined) => void;
}>({
  selectedRouteId: undefined,
  setSelectedRouteId: () => {},
});

export const RoutesContextProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [selectedRouteId, setSelectedRouteId] = useState<string | undefined>(
    undefined,
  );

  return (
    <RoutesContext.Provider value={{ selectedRouteId, setSelectedRouteId }}>
      {children}
    </RoutesContext.Provider>
  );
};

export const useRoutesContext = () => useContext(RoutesContext);
