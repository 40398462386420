import { Heading } from "@components/Heading/Heading";
import styles from "./OtherActivities.module.scss";
import { PlainButton } from "@components/Button/Button";
import { Time } from "@/components/Time/Time";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import {
  activityOccurrenceAndGroupKeys,
  finishActivityOccurrence,
  useOtherEmployeeActivityOccurrences,
} from "@/api/Activities";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import {
  deducedError,
  displayErrorMessageAlert,
  isErrorWithKnownErrorCode,
  knownErrorCodeSchema,
} from "@/Utils/ErrorUtils";
import { Loading } from "@components/Loading/Loading";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import NoResults from "@/components/NoResults/NoResults";
import { isTomorrow } from "date-fns";
import { Text } from "@components/Text/Text";
import { StatusTag } from "@/components/StatusTag/StatusTag";
import type { IActivityOccurrence } from "@models/activities";
import { timeOfDayDictionary, timeOfDaySchema } from "@models/activities";
import { getPatientNameWithStatus } from "@/api/Patients";
import { Link } from "@components/Link/Link";
import * as Sentry from "@sentry/react";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export const OtherActivities = () => {
  const { _ } = useLingui();
  const queryClient = useQueryClient();

  const {
    data: activities,
    isPending,
    isError,
    error,
  } = useOtherEmployeeActivityOccurrences();

  const {
    mutate: finishActivityOccurrenceMutation,
    isPending: isFinishingActivityOccurrence,
  } = useMutation({
    mutationFn: ({
      activityId,
      occurrenceId,
    }: {
      activityId: string;
      occurrenceId: string;
    }) => finishActivityOccurrence(activityId, occurrenceId),
    // MED-2243 - prefer other ways of resolving errors than displaying alerts
    onError: (error) => {
      if (
        isErrorWithKnownErrorCode(error) &&
        error.response.data.code ===
          knownErrorCodeSchema.Values.OccurrenceFinished
      ) {
        return queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.list({
            assignee: "me",
          }),
        });
      } else {
        displayErrorMessageAlert(
          `${t`Gick inte att markera aktivitetstillfället som utfört.`} ${deducedError(
            error,
          )}`,
        );
      }
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: activityOccurrenceAndGroupKeys.list({
          assignee: "me",
        }),
      });
    },
  });

  if (isPending) {
    return <Loading message={t`Hämtar aktiviteter`} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`${t`Kunde inte hämta aktiviteterna.`} ${deducedError(error)}`}
      />
    );
  }

  if (activities?.length === 0) {
    return <NoResults message={t`Du har inga övriga aktiviteter`} />;
  }

  const finishedActivities = activities.filter(
    (activity) => activity.status === "finished",
  );

  const unfinishedActivities = activities.filter(
    (activity) => activity.status !== "finished",
  );

  const anyTime = _(timeOfDayDictionary.Any.short);
  const tomorrowAnyTime = t`Imorgon ${anyTime}`;
  const Item = ({ activity }: { activity: IActivityOccurrence }) => {
    return (
      <>
        <div className={styles.headingAndTime}>
          <Heading level="h2" size="h3">
            {activity.title}
          </Heading>
          <Text element="div">
            <Time>
              {isTomorrow(activity.start) &&
              activity.timeOfDay === timeOfDaySchema.Values.Any
                ? tomorrowAnyTime
                : activity.timeOfDay === timeOfDaySchema.Values.Any
                  ? anyTime
                  : formattedTimeSpan(activity.start, activity.end)}
            </Time>
          </Text>
          {activity.description ? (
            <Text element="p">{activity.description}</Text>
          ) : null}
        </div>
        <div className={styles.patientName}>
          {activity.patient ? (
            <>
              <Text element="div" weight="medium">
                {getPatientNameWithStatus(activity.patient)}
              </Text>
              <Link to={`/ambulating/patients/${activity.patient.id}`}>
                <Trans>Visa patientinformation</Trans>
              </Link>
            </>
          ) : null}
        </div>
        {activity.status !== "finished" ? (
          <PlainButton
            disabled={isFinishingActivityOccurrence}
            onClick={() =>
              finishActivityOccurrenceMutation({
                activityId: activity.activityId,
                occurrenceId: activity.id,
              })
            }
          >
            <Trans>Markera som utfört</Trans>
          </PlainButton>
        ) : (
          !!activity.finishedAt && ( // Check required because TS is stupid
            <div className={styles.statusTag}>
              <StatusTag
                status="finished"
                timestamp={activity.finishedAt}
                size="medium"
              />
            </div>
          )
        )}
      </>
    );
  };

  return (
    <>
      {unfinishedActivities.length > 0 ? (
        <section className={styles.section}>
          <Heading level="h2">
            <Trans>Att göra</Trans>
          </Heading>
          <ul className={styles.activitiesList}>
            {unfinishedActivities.map((activity) => {
              return (
                <li
                  key={activity.id + activity.activityId}
                  className={styles.activityItem}
                >
                  <Item activity={activity} />
                </li>
              );
            })}
          </ul>
        </section>
      ) : null}
      {finishedActivities.length > 0 ? (
        <section className={styles.section}>
          <Heading level="h2">
            <Trans>Utfört</Trans>
          </Heading>
          <ul className={styles.activitiesList}>
            {finishedActivities.map((activity) => {
              return (
                <li
                  key={activity.id + activity.activityId}
                  className={styles.activityItem}
                >
                  <Item activity={activity} />
                </li>
              );
            })}
          </ul>
        </section>
      ) : null}
    </>
  );
};
