import styles from "./NoResults.module.scss";

/**
 * Use **padding** sparingly, prefer to use padding of parent (layout) component
 */
const NoResults = ({
  message,
  padding = 0,
}: {
  message: string;
  padding?: number;
}) => {
  return (
    <p
      className={styles.noResults}
      aria-live="polite"
      style={{ padding: padding }}
    >
      {message}
    </p>
  );
};

export default NoResults;
