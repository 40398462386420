export const PersonalIdentityNumber = ({
  personalIdentityNumber,
}: {
  personalIdentityNumber: string;
}) => {
  const personalIdentityNumberWithHyphen = `${personalIdentityNumber.substring(
    0,
    8,
  )}-${personalIdentityNumber.substring(8, 12)}`;

  return <>{personalIdentityNumberWithHyphen}</>;
};
