import { Outlet } from "react-router-dom";
import styles from "./CommandCenterContainer.module.scss";
import { NotificationPermitter } from "../../components/NotificationPermitter/NotificationPermitter";
import {
  useCenterLogisticsSSEHandlers,
  useCenterPatientSSEHandlers,
} from "@/Utils/sseHandlers";
import Header from "@/pages/commandcenter/Header";
import { useSmsNotificationStatus } from "@/api/SmsNotifications";
import { InlineAlert } from "@components/InlineAlert/InlineAlert";
import { useAppVisibilityBreadcrumbs } from "../useAppVisibilityBreadcrumbs";
import { useCenterBaseUrl } from "@/Utils/useCenterBaseUrl";
import { ChatProvider } from "./ChatProvider";
import { t } from "@lingui/macro";

const browserSupportsNotificationAPI = "Notification" in window;

const CommandCenterContainer = () => {
  const { data: smsNotificationStatus } = useSmsNotificationStatus();
  const centerBaseUrl = useCenterBaseUrl();
  const isCommandCenter = centerBaseUrl === "/commandcenter";
  useCenterLogisticsSSEHandlers();
  useCenterPatientSSEHandlers();
  useAppVisibilityBreadcrumbs();
  return (
    <ChatProvider>
      <Header />
      <section className={styles.banners}>
        {browserSupportsNotificationAPI ? <NotificationPermitter /> : <></>}
        {smsNotificationStatus?.notificationConfig.patientSmsNotification ===
        false ? (
          <InlineAlert
            type="warning"
            title={t`SMS-notiser för patientmeddelanden är avstängda`}
            placement="hug"
          />
        ) : (
          <></>
        )}
        {!isCommandCenter ? (
          <InlineAlert
            type="info"
            title={t`Du använder Center utan chattfunktioner`}
            placement="hug"
          />
        ) : (
          <></>
        )}
      </section>
      <main className={styles.commandCenterContainer}>
        <Outlet />
      </main>
    </ChatProvider>
  );
};

export default CommandCenterContainer;
