import * as Sentry from "@sentry/react";
import type { DropZoneProps } from "react-aria-components";
import { DropZone as ReactAriaDropZone } from "react-aria-components";

interface IDropZone extends DropZoneProps {
  ariaLabel: string;
  children: React.ReactNode;
  className?: string;
  getDroppedText: (droppedText: string) => void;
}

export const DropZone = ({
  ariaLabel,
  children,
  className,
  isDisabled,
  getDroppedText: getDroppedText,
}: IDropZone) => {
  return (
    <ReactAriaDropZone
      aria-label={ariaLabel}
      className={className}
      isDisabled={isDisabled}
      onDrop={async (e) => {
        const allDroppedItems = e.items;
        allDroppedItems.forEach(async (droppedItem) => {
          if (droppedItem.kind !== "text") {
            Sentry.captureException(
              new Error("A dropped item did not have kind 'text'"),
            );
            return;
          }
          // We use 'medoma-item', but other options are available, see: https://react-spectrum.adobe.com/react-aria/useDraggableCollection.html#drag-data
          if (!droppedItem.types.has("medoma-item")) {
            Sentry.captureException(
              new Error("A dropped item was not of type 'medoma-item'"),
            );
            return;
          }
          const droppedText = await droppedItem.getText("medoma-item");

          getDroppedText(droppedText);
        });
      }}
    >
      {children}
    </ReactAriaDropZone>
  );
};
