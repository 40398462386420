/* eslint-disable @typescript-eslint/no-unused-vars */
// We have a pattern to use types based on zod values in these files.

import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { notificationApi } from "./ApiClient";

export const notificationKeys = {
  all: ["notification"] as const,
  sms: () => [...notificationKeys.all, "sms"] as const,
};

const smsNotificationStatusSchema = z.object({
  notificationConfig: z.object({
    patientSmsNotification: z.boolean(),
    employeeSmsNotification: z.boolean(),
  }),
});
export type ISmsNotificationStatus = z.infer<
  typeof smsNotificationStatusSchema
>;

const notificationTypeSchema = z.enum(["patient", "employee"]);
export type INotificationType = z.infer<typeof notificationTypeSchema>;

export const useSmsNotificationStatus = () => {
  return useQuery({
    queryKey: notificationKeys.sms(),
    queryFn: async () => {
      const url = `center/sms`;
      const response = await notificationApi.get(url);
      return smsNotificationStatusSchema.parse(response.data);
    },
  });
};

export const setSmsNotificationStatus = async (
  notificationType: INotificationType,
  status: boolean,
) => {
  await notificationApi.put("center/sms", {
    notificationType: notificationType,
    enabled: status,
  });
};
