import type { IActivityRequirementStatus } from "@models/activities";

export const getBackground = (state: IActivityRequirementStatus) => {
  switch (state) {
    case "unfulfilled":
      return "none";
    case "assigned":
      return "solid";
    case "unassigned":
      return "none";
    case "neutral":
    default:
      return "solid";
  }
};

export const getBorder = (state: IActivityRequirementStatus) => {
  switch (state) {
    case "unfulfilled":
      return "dashed";
    case "assigned":
      return "none";
    case "unassigned":
      return "dashed";
    case "neutral":
    default:
      return "none";
  }
};
