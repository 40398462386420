import styles from "./Header.module.scss";
import { logout } from "@/Utils/InloggedUtils";
import Person from "@/components/Person/Person";
import { Loading, LoadingOverlay } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { useUser } from "@/api/Users";
import { useMsal } from "@azure/msal-react";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu";
import LogoutIcon from "@components/icons/SVG/logout.svg?react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoText from "@components/icons/LogoText";
import { useCurrentEmployee } from "@/api/Employee";
import SentryFeedbackButton from "@/components/SentryFeedbackButton/SentryFeedbackButton";
import { deducedError } from "@/Utils/ErrorUtils";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useUnreadNotificationCounts } from "@chat/useUnreadNotificationCounts";
import { ROOM_ALIAS_PREFIX_EMPLOYEE } from "@chat/matrixClient";
import { UnstyledLink } from "@components/Link/Link";
import NotificationCircle from "@components/NotificationCircle/NotificationCircle";
import ChatConversationIcon from "@components/icons/ChatConversation";
import { IconButton } from "@components/Button/Button";
import CaretLeftIcon from "@components/icons/CaretLeftIcon";
import { employeeName } from "@models/shifts";
import { t, Trans } from "@lingui/macro";

const HomeLink = () => (
  <Link aria-label={t`Startsida`} to="/" className={styles.homeLink}>
    <LogoText />
  </Link>
);

const NavigateBack = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.navigateBackButtonWrapper}>
      <IconButton aria-label={t`Gå tillbaka`} onClick={() => navigate(-1)}>
        <CaretLeftIcon />
      </IconButton>
    </div>
  );
};

const ChatLink = () => {
  const { data: employee } = useCurrentEmployee();
  useEffect(() => {
    if (employee) {
      Sentry.setUser({
        username: employeeName(employee),
      });
    }
  }, [employee]);

  const numberOfUnreadMessages = useUnreadNotificationCounts({
    roomName: `${ROOM_ALIAS_PREFIX_EMPLOYEE}${employee?.id.toString()}`,
  });

  const hasCompletedInitialSync = numberOfUnreadMessages !== undefined;

  const hasUnreadChatMessages =
    hasCompletedInitialSync && numberOfUnreadMessages > 0;

  return (
    <UnstyledLink to="chat">
      <div className={styles.iconLink}>
        <LoadingOverlay show={!hasCompletedInitialSync}>
          <NotificationCircle
            placement="top-left"
            isVisible={hasUnreadChatMessages}
            number={numberOfUnreadMessages}
          >
            <ChatConversationIcon />
          </NotificationCircle>
        </LoadingOverlay>
      </div>
    </UnstyledLink>
  );
};

const Header = () => {
  const { data: user, isPending, isError, error } = useUser();
  const { data: employee } = useCurrentEmployee();
  const { instance } = useMsal();
  const { pathname } = useLocation();
  const isOnAmbulatingHome = pathname === "/ambulating/routes";

  const CustomTrigger = () => (
    <Person
      name={user?.givenName ?? ""}
      photoUrl={employee?.imageURL ?? null}
    />
  );

  const DisplayError = () => {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`${t`Kunde inte hämta användarprofil.`} ${deducedError(error)}`}
      />
    );
  };

  return (
    <header className={styles.header}>
      {isOnAmbulatingHome ? <div /> : <NavigateBack />}
      <div className={styles.alignCenter}>
        <HomeLink />
      </div>
      <div className={styles.alignRight}>
        <ChatLink />
        {isPending ? (
          <Loading message={t`Laddar användare`} />
        ) : isError ? (
          <DisplayError />
        ) : (
          <DropdownMenu
            trigger={{
              custom: <CustomTrigger />,
              ariaLabel: t`Meny`,
            }}
          >
            <DropdownMenu.Item content={<SentryFeedbackButton />} />
            <DropdownMenu.Separator />
            <DropdownMenu.Item
              action={() => logout(instance)}
              content={
                <>
                  <LogoutIcon /> <Trans>Logga ut</Trans>
                </>
              }
            />
          </DropdownMenu>
        )}
      </div>
    </header>
  );
};

export default Header;
