import { closeActivity } from "@/api/Activities";
import { PlainButton } from "@components/Button/Button";
import CalendarEditIcon from "@components/icons/CalendarEditIcon";
import CalendarDeleteIcon from "@components/icons/CalendarDeleteIcon";
import RecurrenceIcon from "@components/icons/RecurrenceIcon";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import styles from "./RecurringActivitySeries.module.scss";
import Chip from "@/components/Chips/Chip";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ActivityEndDateForm } from "@/forms/ActivityEndDateForm";
import { Dialog } from "@components/Dialog/Dialog";
import { ActivityInformation } from "./ActivityInformation";
import { t, Trans } from "@lingui/macro";

const RecurringActivitySeries = () => {
  const [activityEndDateDialogIsOpen, setActivityEndDateDialogIsOpen] =
    useState(false);

  const { activityId, occurrenceId } = z
    .object({ activityId: z.string(), occurrenceId: z.string() })
    .parse(useParams());

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { mutate: closeActivityMutation, isPending: isClosingActivity } =
    useMutation({
      mutationFn: ({ activityId }: { activityId: string }) =>
        closeActivity(activityId),
      onError: (error) => {
        displayErrorMessageAlert(
          `${t`Gick inte att stänga aktiviteten.`} ${deducedError(error)}`,
        );
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries();
        navigate("../..");
      },
    });

  return (
    <>
      <>
        <div className={styles.activityInfoHeader}>
          <Chip iconStart={<RecurrenceIcon />}>
            <Trans>Aktivitetsserie</Trans>
          </Chip>
          <PlainButton
            size="small"
            weight="light"
            onClick={() => setActivityEndDateDialogIsOpen(true)}
          >
            <CalendarEditIcon />
            <Trans>Ändra slutdatum</Trans>
          </PlainButton>
          <PlainButton
            size="small"
            weight="light"
            onClick={() => closeActivityMutation({ activityId })}
            disabled={isClosingActivity}
          >
            <CalendarDeleteIcon />
            <Trans>Avsluta nu</Trans>
          </PlainButton>
        </div>
      </>
      <ActivityInformation
        activityId={activityId}
        occurrenceId={occurrenceId}
        isRecurringTab={true}
      />
      <Dialog
        isOpen={activityEndDateDialogIsOpen}
        onClose={() => setActivityEndDateDialogIsOpen(false)}
        title={t`Slutdatum för aktivitetsserie`}
      >
        {activityEndDateDialogIsOpen ? (
          <ActivityEndDateForm
            activityId={activityId}
            onSubmitSuccess={() => setActivityEndDateDialogIsOpen(false)}
          />
        ) : (
          <></>
        )}
      </Dialog>
    </>
  );
};

export default RecurringActivitySeries;
