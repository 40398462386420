import { useHomeVisitActivityOccurrences } from "@/api/Activities";
import type {
  CircleLayerSpecification,
  SymbolLayerSpecification,
  FillLayerSpecification,
  LineLayerSpecification,
} from "mapbox-gl";
import { HOSPITAL_COORDINATES } from "@/Utils/EnvUtils";
import Map from "@/components/Map/Map";
import { format } from "@models/date-and-time";
import { useContext, useMemo, useState } from "react";
import type { ViewState } from "react-map-gl";
import { Layer, Source } from "react-map-gl";
import { getVisitsCoordinates, useRoutes } from "@/api/Routes";
import { useVehicles } from "@/api/Vehicles";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { useDirections } from "@/api/Routes";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { RoutesContext } from "./RoutesContext";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import type { IListExistingPatient } from "@/api/Patients";
import { patientStatusSchema } from "@models/patients";

const formatter = new Intl.ListFormat(navigator.language, {
  style: "long",
  type: "disjunction",
});

const lineLayer: LineLayerSpecification = {
  source:
    "String needed. Check this issue: https://github.com/visgl/react-map-gl/issues/2411",
  id: "route-visualization",
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": ["get", "color"],
    "line-opacity": ["get", "opacity"],
    "line-width": 4,
  },
};

const symbolLayer: SymbolLayerSpecification = {
  source:
    "String needed. Check this issue: https://github.com/visgl/react-map-gl/issues/2411",
  id: "route-labels",
  type: "symbol",
  layout: {
    "text-field": ["get", "name"],
    "text-offset": [0, 1],
    "text-size": ["get", "size"],
  },
  paint: {
    "text-color": ["get", "color"],
    "text-halo-color": "#fff",
    "text-halo-width": 3,
  },
};

const clusterLayer = (
  entity: "patient" | "vehicle",
): CircleLayerSpecification => {
  return {
    source:
      "String needed. Check this issue: https://github.com/visgl/react-map-gl/issues/2411",
    id: `${entity}-clusters`,
    type: "circle",
    filter: ["has", "point_count"],
    paint: {
      "circle-color":
        entity === "patient"
          ? // If `point_count` is 2, use green-50 (lighter), otherwise (count must be 3+) use green-40 (darker)
            [
              "case",
              ["==", ["get", "point_count"], 2],
              "#0d8f2a", // green-50
              "#047003", // green-40
            ]
          : "#7488ed",
      "circle-radius": 10,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#000",
      "circle-opacity": 0.8,
    },
  };
};

const circleNameLayer = (
  entity: "patient" | "vehicle",
): SymbolLayerSpecification => {
  return {
    source:
      "String needed. Check this issue: https://github.com/visgl/react-map-gl/issues/2411",
    id: `${entity}-cluster-count`,
    type: "symbol",
    layout: {
      // If there is a `name` field, use it, otherwise use the `point_count` field (e.g. 2) and append " bilar" or " patienter"
      "text-field": [
        "coalesce",
        ["get", "name"],
        [
          "concat",
          ["get", "point_count"],
          entity === "vehicle" ? " " + t`bilar` : " " + t`patienter`,
        ],
      ],
      // -1.5 puts the label over the circle instead of on top of it. Dependent on "circle-radius": 10,
      "text-offset": [0, -1.5],
      "text-size": 12,
    },
    paint: {
      "text-halo-color": "#fff",
      "text-halo-width": 1.5,
    },
  };
};

const unclusteredLayer = (
  entity: "patient" | "vehicle",
): CircleLayerSpecification => {
  return {
    source:
      "String needed. Check this issue: https://github.com/visgl/react-map-gl/issues/2411",
    id: `${entity}-unclustered-point`,
    type: "circle",
    // Only show unclustered points when not part of a cluster
    filter: ["!", ["has", "point_count"]],
    paint: {
      "circle-color":
        entity === "vehicle"
          ? "#66bfff" // --color-primary-70
          : "#1aa339", // --green-60
      "circle-radius": 10,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#000",
      "circle-opacity": 0.8,
    },
  };
};

const INITIAL_STATIC_MAP_VIEW = {
  longitude: HOSPITAL_COORDINATES.longitude,
  latitude: HOSPITAL_COORDINATES.latitude,
  zoom: 9,
  bearing: 0,
  pitch: 0,
  padding: { top: 0, left: 0, right: 0, bottom: 0 },
};

const hospitalZone = {
  type: "Feature" as const,
  properties: {},
  geometry: {
    type: "Polygon" as const,
    coordinates: [
      [
        [18.02, 59.3325],
        [18.0235, 59.3324],
        [18.0245, 59.334],
        [18.0188, 59.3361],
        [18.017, 59.335],
        [18.02, 59.3325],
      ],
    ],
  },
};

const hospitalZoneLayer: FillLayerSpecification = {
  source:
    "String needed. Check this issue: https://github.com/visgl/react-map-gl/issues/2411",
  id: "hospital-zone",
  type: "fill",
  paint: {
    "fill-color": "#2d4ad9", // var(--color-primary-50)
    "fill-opacity": 0.3,
  },
};

export const RoutesMap = () => {
  const { _ } = useLingui();
  const selectedDate = new Date(useSelectedDate());
  const { selectedRouteId: activeRouteId } = useContext(RoutesContext);
  const formattedSelectedDate = format(selectedDate, "yyyy-MM-dd");
  const { data: activityOccurrences = [], isError } =
    useHomeVisitActivityOccurrences(
      formattedSelectedDate,
      formattedSelectedDate,
    );

  const { data: vehicles = [], isError: isErrorVehicles } = useVehicles();

  const [mapState, setMapState] = useState<ViewState>(INITIAL_STATIC_MAP_VIEW);

  const { data: routes = [], isError: isErrorRoutes } = useRoutes({
    date: formattedSelectedDate,
  });

  const allRoutesCoordinates = routes.map(({ visits }) =>
    getVisitsCoordinates(visits),
  );
  const { data: directions } = useDirections(allRoutesCoordinates);

  const patientCoordinates = useMemo(
    () => [
      ...((
        activityOccurrences.filter(
          ({ patient }) =>
            patient.status !== patientStatusSchema.Values.deleted,
        ) as ReadonlyArray<{ patient: IListExistingPatient }>
      ).map(({ patient }) => patient.address.coordinates) ?? []),
      HOSPITAL_COORDINATES,
    ],
    [activityOccurrences],
  );

  const formattedErrors = formatter.format(
    [
      isError ? t`patienterna` : "",
      isErrorRoutes ? t`rutterna` : "",
      isErrorVehicles ? t`bilarna` : "",
    ].filter((errorMessage) => errorMessage.length > 0),
  );
  const customErrorMessage = [isError, isErrorRoutes, isErrorVehicles].some(
    (error) => error,
  )
    ? t`Kunde inte visa ${formattedErrors} i kartan. Försök att ladda om sidan, och kontakta teknisk support om det ändå inte fungerar.`
    : null;

  const routeLines = useMemo(() => {
    const visitCoordinates = routes.map((route) =>
      getVisitsCoordinates(route.visits).map(({ longitude, latitude }) => [
        longitude,
        latitude,
      ]),
    );
    return {
      type: "FeatureCollection" as const,
      features: routes.map((route, index) => {
        const carDirections = directions?.[index];
        const birdDirections = visitCoordinates[index]
          ? [
              [HOSPITAL_COORDINATES.longitude, HOSPITAL_COORDINATES.latitude],
              ...visitCoordinates[index],
              [HOSPITAL_COORDINATES.longitude, HOSPITAL_COORDINATES.latitude],
            ]
          : [];

        return {
          type: "Feature" as const,
          properties: {
            color:
              route.id === activeRouteId
                ? "#2d4ad9" // var(--color-primary-50)
                : "#3d3d3d", // var(--color-neutral-10)
            opacity: route.id === activeRouteId ? 1 : 0.2,
          },
          geometry: {
            type: "LineString" as const,
            coordinates: carDirections?.coordinates ?? birdDirections,
          },
        };
      }),
    };
  }, [activeRouteId, directions, routes]);

  const routeLabels = useMemo(() => {
    const visitLabels = routes
      .filter((route) => route.visits.length >= 1)
      .flatMap((route) => {
        const coordinatesWithIndexes = route.visits.reduce(
          (coordinatesWithIndexes, visit, index) => {
            if (visit.patient.status === patientStatusSchema.Values.deleted) {
              return coordinatesWithIndexes;
            }

            const {
              patient: {
                address: { coordinates },
                id: patientId,
              },
            } = visit;

            if (coordinatesWithIndexes[patientId]) {
              return {
                ...coordinatesWithIndexes,
                [patientId]: {
                  coordinates: coordinates,
                  indexes: [
                    ...coordinatesWithIndexes[patientId].indexes,
                    index,
                  ],
                },
              };
            }
            return {
              ...coordinatesWithIndexes,
              [patientId]: {
                coordinates: coordinates,
                indexes: [index],
              },
            };
          },
          {} as Record<
            string,
            {
              coordinates: { longitude: number; latitude: number };
              indexes: Array<number>;
            }
          >,
        );

        return Object.entries(coordinatesWithIndexes).map(
          ([_, { coordinates, indexes }]) => ({
            type: "Feature" as const,
            properties: {
              name: indexes.map((index) => index + 1).join(", "),
              color: "#2d4ad9", // var(--color-primary-50)
              size: route.id === activeRouteId ? 24 : 0, //px
            },
            geometry: {
              type: "Point" as const,
              coordinates: [coordinates.longitude, coordinates.latitude],
            },
          }),
        );
      });

    const routeLabels = {
      type: "FeatureCollection" as const,
      features: visitLabels,
    };
    return routeLabels;
  }, [activeRouteId, routes]);

  const vehicleLabels = useMemo(() => {
    return {
      type: "FeatureCollection" as const,
      features:
        vehicles.map((vehicle) => ({
          type: "Feature" as const,
          properties: {
            name: vehicle.licensePlate,
            color: "#66bfff", // --color-primary-70
          },
          geometry: {
            type: "Point" as const,
            coordinates: [
              vehicle.location.longitude,
              vehicle.location.latitude,
            ],
          },
        })) ?? [],
    };
  }, [vehicles]);

  const patientLabels = useMemo(() => {
    const patientIds =
      activityOccurrences.map(
        (activityOccurrence) => activityOccurrence.patient.id,
      ) ?? [];

    const uniqueNonDeletedPatients =
      (
        activityOccurrences.filter(
          ({ patient: { id, status } }, index) =>
            status !== patientStatusSchema.Values.deleted &&
            !patientIds.includes(id, index + 1),
        ) as ReadonlyArray<{ patient: IListExistingPatient }>
      ).map((activityOccurrence) => activityOccurrence.patient) ?? [];

    const patientsWithActivityOccurrences = uniqueNonDeletedPatients.map(
      (uniqueNonDeletedPatient) => ({
        ...uniqueNonDeletedPatient,
        activityOccurrences:
          activityOccurrences.filter(
            (activityOccurrence) =>
              activityOccurrence.patient.id === uniqueNonDeletedPatient.id,
          ) ?? [],
      }),
    );

    const patientLabels = {
      type: "FeatureCollection" as const,
      features: patientsWithActivityOccurrences.map((patient) => ({
        type: "Feature" as const,
        properties: {
          name: patient.name,
          color: "#66bfff", // --color-primary-70
        },
        geometry: {
          type: "Point" as const,
          coordinates: [
            patient.address.coordinates.longitude,
            patient.address.coordinates.latitude,
          ],
        },
      })),
    };

    return patientLabels;
  }, [activityOccurrences]);

  return (
    <>
      {customErrorMessage ? (
        <ErrorMessage message={customErrorMessage} />
      ) : (
        <> </>
      )}
      <Map
        id="planningMap"
        coordinatesToShow={patientCoordinates}
        viewState={mapState}
        setViewState={setMapState}
        style={{
          height: "100%",
        }}
      >
        <Source data={hospitalZone} type="geojson">
          <Layer {...hospitalZoneLayer} />
        </Source>
        <Source data={routeLines} type="geojson">
          <Layer {...lineLayer} />
        </Source>
        <Source data={routeLabels} type="geojson">
          <Layer {...symbolLayer} />
        </Source>
        <Source
          data={patientLabels}
          type="geojson"
          cluster={true}
          clusterRadius={20}
        >
          <Layer {...clusterLayer("patient")} />
          <Layer {...unclusteredLayer("patient")} />
          <Layer {...circleNameLayer("patient")} />
        </Source>
        <Source
          data={vehicleLabels}
          type="geojson"
          cluster={true}
          clusterRadius={20}
        >
          <Layer {...clusterLayer("vehicle")} />
          <Layer {...unclusteredLayer("vehicle")} />
          <Layer {...circleNameLayer("vehicle")} />
        </Source>
      </Map>
    </>
  );
};
