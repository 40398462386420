/* eslint-disable @typescript-eslint/no-unused-vars */
// We have a pattern to use types based on zod values in these files.

import { logisticsApi } from "./ApiClient";
import { LOGISTICS_API_URL } from "../Utils/EnvUtils";
import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { generateQueryString } from "./Helpers";
import type {
  IConvertedPatientNoteWithPatientId,
  ICreatePatientNote,
  IPatientNoteStatus,
} from "@models/patientNotes";
import {
  convertedPatientNoteWithPatientIdSchema,
  patientNoteStatusSchema,
} from "@models/patientNotes";

export async function convertPatientNote(patientNote: ICreatePatientNote) {
  await logisticsApi.post(`${LOGISTICS_API_URL}/conversion/patient-note`, {
    ...patientNote,
  });
}

const acceptedConvertedPatientNoteSchema = z.object({
  id: z.string().uuid(),
  updatedText: z.string(),
});

export type IAcceptedConvertedPatientNote = z.infer<
  typeof acceptedConvertedPatientNoteSchema
>;

export async function acceptConvertedPatientNote(
  convertedPatientNote: IAcceptedConvertedPatientNote & {
    status: IPatientNoteStatus;
  },
) {
  const shouldUpdate =
    convertedPatientNote.status === patientNoteStatusSchema.Values.accepted;

  if (shouldUpdate) {
    await logisticsApi.put(
      `${LOGISTICS_API_URL}/conversion/patient-note/${convertedPatientNote.id}/accept`,
      {
        updatedText: convertedPatientNote.updatedText,
      },
    );
  } else {
    await logisticsApi.post(
      `${LOGISTICS_API_URL}/conversion/patient-note/${convertedPatientNote.id}/accept`,
      {
        updatedText: convertedPatientNote.updatedText,
      },
    );
  }
}

export const fetchPatientNoteById = async (
  patientId: string,
  status: IPatientNoteStatus,
): Promise<IConvertedPatientNoteWithPatientId | null> => {
  const queryString = generateQueryString({
    status: status,
  });

  let response;

  try {
    response = await logisticsApi.get(
      `${LOGISTICS_API_URL}/conversion/patient-note/latest/patient/${patientId}${queryString}`,
    );
  } catch {
    return null;
  }

  const convertedNoteWithPatientId = {
    patientId,
    ...response.data,
  };

  return convertedPatientNoteWithPatientIdSchema.parse(
    convertedNoteWithPatientId,
  );
};

export const patientNoteKeys = {
  all: ["conversion"] as const,
  lists: () => [...patientNoteKeys.all, "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...patientNoteKeys.lists(), filters] as const,
  detail: (patientId: string, status: IPatientNoteStatus) =>
    [...patientNoteKeys.all, patientId, "details", status] as const,
};

export const usePatientNote = (
  patientId: string,
  status: IPatientNoteStatus,
) => {
  return useQuery({
    queryKey: patientNoteKeys.detail(patientId, status),
    queryFn: () => fetchPatientNoteById(patientId, status),
  });
};
