import type { Location } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";
import { InteractionType } from "@azure/msal-browser";
import { useIsAuthenticated, useMsalAuthentication } from "@azure/msal-react";
import { Loading } from "@components/Loading/Loading";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

function Login() {
  const { _ } = useLingui();

  const isAuthenticated = useIsAuthenticated();
  const { state }: { state?: { from?: Location } } = useLocation();
  const requestedURL = state?.from
    ? `${state.from.pathname}${state.from.search}`
    : "/";

  const { result } = useMsalAuthentication(InteractionType.Redirect, {
    state: requestedURL,
  });

  const redirectURL =
    // If there is a state in the result object, it means that the user made a round-trip to MSFT, and this is where originally wanted to go. Respect that firstly.
    // If there is a from in the state object, it means that the user was redirected internally, and this is where they originally wanted to go. Respect that secondly.
    // Default to root.
    result?.state
      ? result.state
      : state?.from
        ? `${state.from.pathname}${state.from.search}`
        : "/";

  // Once we become authenticated go to where we originally wanted to go
  if (isAuthenticated) {
    return <Navigate to={redirectURL} replace />;
  }

  return <Loading message={_(msg`Loggar in`)} padding={24} />;
}

export default Login;
