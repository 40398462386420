import NotificationCircle from "@components/NotificationCircle/NotificationCircle";
import styles from "./IconWithIndicator.module.scss";
import clsx from "clsx";

type IconWithIndicatorProps = {
  icon: JSX.Element;
  indicatorIsActive: boolean;
  number?: number;
  passiveState?: "hidden" | "visible";
};

const IconWithIndicator = ({
  icon,
  indicatorIsActive,
  number,
  passiveState = "hidden",
}: IconWithIndicatorProps) => {
  if (passiveState === "hidden" && !indicatorIsActive) {
    return null;
  }

  return (
    <div className={clsx([indicatorIsActive ? styles.active : styles.passive])}>
      <NotificationCircle
        isVisible={indicatorIsActive}
        placement="top-left"
        number={number}
      >
        {icon}
      </NotificationCircle>
    </div>
  );
};

export default IconWithIndicator;
