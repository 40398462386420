import Portrait from "@components/Portrait/Portrait";
import styles from "./Person.module.scss";

interface IPerson {
  age?: number;
  name: string;
  photoUrl: string | null;
}
const Person = ({ age, name, photoUrl }: IPerson) => {
  return (
    <div className={styles.person}>
      <Portrait name={name} photoUrl={photoUrl} size="small" />
      <span className={styles.name}>{`${name}${age ? `, ${age}` : ""}`}</span>
    </div>
  );
};

export default Person;
