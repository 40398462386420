import ClockIcon from "@components/icons/ClockIcon";
import styles from "./Time.module.scss";

export const Time = ({
  children,
  showIcon = false,
}: {
  showIcon?: boolean;
  children: string;
}) => (
  <div className={styles.time}>
    {showIcon ? <ClockIcon /> : <></>}
    <time>{children}</time>
  </div>
);
