import {
  removeActivityOccurrence,
  useActivityOccurrence,
} from "@/api/Activities";
import { PlainButton } from "@components/Button/Button";
import { CompetenceChip } from "@/components/Chips/CompetenceChip";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import styles from "./SingleActivityOccurrence.module.scss";
import * as Sentry from "@sentry/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { StatusTagWithDropdown } from "@/components/StatusTagWithDropdown/StatusTagWithDropdown";
import BinIcon from "@components/icons/BinIcon";
import { ChangeGroup } from "@/forms/ChangeGroup";
import ArrowLeftCurveIcon from "@components/icons/ArrowLeftCurveIcon";
import { Text } from "@components/Text/Text";
import { EditButton } from "./EditButton";
import { ActivityInformation } from "./ActivityInformation";
import { useCurrentlyEditing } from "./useCurrentlyEditing";
import { useCloseActivity } from "./useCloseActivity";
import { EditContainer } from "./EditContainer";
import { t, Trans } from "@lingui/macro";
import {
  activityOccurrenceStatusSchema,
  categorySchema,
} from "@models/activities";

const SingleActivityOccurrence = () => {
  const { activityId, occurrenceId } = z
    .object({ activityId: z.string(), occurrenceId: z.string() })
    .parse(useParams());

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const {
    data: occurrence,
    isPending: occurrenceIsPending,
    isError: occurrenceIsError,
    error: occurrenceError,
  } = useActivityOccurrence(activityId, occurrenceId);
  const { currentlyEditing, setCurrentlyEditing } = useCurrentlyEditing();

  const { closeActivityMutation, isClosingActivity } = useCloseActivity();

  const {
    mutate: removeActivityOccurrenceMutation,
    isPending: isClosingActivityOccurrence,
  } = useMutation({
    mutationFn: ({
      activityId,
      occurrenceId,
    }: {
      activityId: string;
      occurrenceId: string;
    }) => removeActivityOccurrence(activityId, occurrenceId),
    onError: (error) => {
      displayErrorMessageAlert(
        `${t`Gick inte att ta bort aktivitetstillfället.`} ${deducedError(error)}`,
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries();
      navigate("../..");
    },
  });

  const activityOccurrenceIsRemovable = ({
    occurrenceStatus,
  }: {
    occurrenceStatus: string;
  }) => {
    return (
      occurrenceStatus !== activityOccurrenceStatusSchema.Values.ongoing ||
      window.confirm(
        t`Är du säker på att du vill ta bort en aktivitet i ett pågående besök?`,
      )
    );
  };

  if (occurrenceIsPending) {
    return <Loading message={t`Hämtar aktivitet`} padding={24} />;
  }

  if (occurrenceIsError) {
    Sentry.captureException(occurrenceError);
    return (
      <ErrorMessage message={deducedError(occurrenceError)} padding={24} />
    );
  }

  return (
    <div>
      <div className={styles.statusAndInfoHeader}>
        <div className={styles.statusAndActionButtons}>
          <div className={styles.statusAndCompetences}>
            <StatusTagWithDropdown
              status={occurrence.status}
              activityId={occurrence.activityId}
              occurrenceId={occurrence.id}
              category={occurrence.category}
              variant="icon-and-text"
            />
            {occurrence.recurring &&
              (occurrence.category === categorySchema.Values.VideoCall ||
                occurrence.category === categorySchema.Values.HomeVisit ||
                occurrence.category === categorySchema.Values.AdminTask) && (
                <ul className={styles.chips}>
                  {occurrence.requiredCompetences.map((competence) => (
                    <li key={competence}>
                      <CompetenceChip competence={competence} state="neutral" />
                    </li>
                  ))}
                </ul>
              )}
          </div>
          {occurrence.recurring ? (
            <PlainButton
              size="small"
              weight="light"
              onClick={() =>
                activityOccurrenceIsRemovable({
                  occurrenceStatus: occurrence.status,
                })
                  ? removeActivityOccurrenceMutation({
                      activityId,
                      occurrenceId,
                    })
                  : null
              }
              disabled={isClosingActivityOccurrence}
            >
              <BinIcon />
              <Trans>Ta bort</Trans>
            </PlainButton>
          ) : (
            <PlainButton
              size="small"
              weight="light"
              onClick={() =>
                activityOccurrenceIsRemovable({
                  occurrenceStatus: occurrence.status,
                })
                  ? closeActivityMutation({
                      activityId,
                    })
                  : null
              }
              disabled={isClosingActivity}
            >
              <BinIcon />
              <Trans>Ta bort</Trans>
            </PlainButton>
          )}
        </div>
        {occurrence.category === categorySchema.Values.HomeVisit &&
        occurrence.status !== activityOccurrenceStatusSchema.Values.finished ? (
          <section className={styles.informationSection}>
            <div className={styles.editableSection}>
              <Text element="p">
                <Trans>Tillfället tillhör ett besök</Trans>
              </Text>

              <EditButton
                attribute="move"
                iconStart={<ArrowLeftCurveIcon />}
                label={t`Flytta`}
                isEditing={currentlyEditing === "move"}
                toggleIsEditing={() =>
                  setCurrentlyEditing(
                    currentlyEditing === "move" ? null : "move",
                  )
                }
              />
            </div>
            {currentlyEditing === "move" ? (
              <EditContainer>
                <ChangeGroup
                  activityOccurrence={{
                    ...occurrence,
                    aloneInGroup: false,
                  }}
                  onSubmitSuccess={() => {
                    setCurrentlyEditing(null);
                  }}
                />
              </EditContainer>
            ) : null}
          </section>
        ) : null}
      </div>
      <ActivityInformation
        isRecurringTab={false}
        activityId={activityId}
        occurrenceId={occurrenceId}
      />
    </div>
  );
};

export default SingleActivityOccurrence;
