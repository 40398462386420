import PhoneIcon from "@components/icons/PhoneIcon";
import { Fragment } from "react";
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import { t } from "@lingui/macro";

export const CallChooser = ({
  contacts,
}: {
  contacts: ReadonlyArray<{ name: string; phoneNumber: string }>;
}) => {
  return (
    <DropdownMenu
      trigger={{ icon: <PhoneIcon />, ariaLabel: t`Ring telefonsamtal` }}
    >
      {contacts.map(({ name, phoneNumber }, index) => (
        <Fragment key={name}>
          {index === 1 ? <DropdownMenu.Separator /> : undefined}
          <DropdownMenu.Item
            action={() => window.open(`tel:${phoneNumber}`, "_self")}
            content={`${name}`}
          />
        </Fragment>
      ))}
    </DropdownMenu>
  );
};
