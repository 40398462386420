export const generateRandomUUID = () => {
  if (import.meta.env.MODE === "test") {
    return `${Math.random().toString(16).substring(2, 10)}-${Math.random()
      .toString(16)
      .substring(2, 6)}-${Math.random()
      .toString(16)
      .substring(2, 6)}-${Math.random()
      .toString(16)
      .substring(2, 6)}-${Math.random().toString(16).substring(2, 14)}`;
  }
  return crypto.randomUUID();
};
