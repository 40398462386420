import ChatConversationIcon from "@components/icons/ChatConversation";
import IconWithIndicator from "../IconWithIndicator";

type UnreadChatMessagesIndicatorProps = {
  hasUnreadMessages: boolean;
  unreadMessagesCount?: number;
  passiveState?: "hidden" | "visible";
};

const UnreadChatMessagesIndicator = ({
  hasUnreadMessages,
  unreadMessagesCount,
  passiveState = "hidden",
}: UnreadChatMessagesIndicatorProps) => {
  return (
    <IconWithIndicator
      icon={<ChatConversationIcon />}
      indicatorIsActive={hasUnreadMessages}
      number={unreadMessagesCount}
      passiveState={passiveState}
    />
  );
};

export default UnreadChatMessagesIndicator;
