import { FilledButton } from "@components/Button/Button";
import { Iframe } from "@components/Iframe/Iframe";
import styles from "./CompodiumVideo.module.scss";
import { useMutation } from "@tanstack/react-query";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { callPatient } from "@/api/Video";
import PhoneIcon from "@components/icons/PhoneIcon";
import { t, Trans } from "@lingui/macro";

export const CompodiumVideo = ({
  meetingUrl,
  patientId,
}: {
  meetingUrl: string;
  patientId: string;
}) => {
  const { mutate, isPending } = useMutation({
    mutationFn: ({ patientId }: { patientId: string }) =>
      callPatient(patientId, "compodium"),
    onError: (error) => {
      displayErrorMessageAlert(
        `${t`Gick inte att ringa patienten.`} ${deducedError(error)}`,
      );
    },
  });
  return (
    <>
      <Iframe
        src={meetingUrl}
        allow="camera;microphone;"
        loadingMessage={t`Startar video`}
        title={t`Video`}
      />
      <div className={styles.buttonWrapper}>
        <FilledButton
          size="small"
          disabled={isPending}
          onClick={() => {
            mutate({ patientId });
          }}
        >
          <PhoneIcon />
          <Trans>Ring patient</Trans>
        </FilledButton>
      </div>
    </>
  );
};
