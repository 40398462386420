import styles from "./ShiftsSection.module.scss";
import { shiftsWithContentsQueryOptions } from "@/api/Shifts";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Heading } from "@components/Heading/Heading";
import NoResults from "@/components/NoResults/NoResults";
import * as Sentry from "@sentry/react";
import { deducedError } from "@/Utils/ErrorUtils";
import { ShiftRow } from "./ShiftsRow";
import { useQuery } from "@tanstack/react-query";
import { t, Trans } from "@lingui/macro";

export const ShiftsSection = ({
  isExpanded,
  onPendingAddToShift,
}: {
  isExpanded: boolean;
  onPendingAddToShift: (onPending: boolean) => void;
}) => {
  const selectedDate = useSelectedDate();

  const {
    data: shiftsWithContents,
    isPending,
    isError,
    error,
  } = useQuery(shiftsWithContentsQueryOptions(selectedDate));

  if (isPending) {
    return <Loading message={t`Hämtar arbetspass`} padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`${t`Gick inte att hämta arbetspassen.`} ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  const ambulatingShiftsWithContents = shiftsWithContents.filter(
    ({ shift }) => shift.type === "Ambulating",
  );
  const otherShiftsWithContents = shiftsWithContents.filter(
    ({ shift }) => shift.type !== "Ambulating",
  );

  return (
    <>
      <section className={styles.shiftsSection}>
        <Heading className={styles.shiftsListHeader} level="h2">
          <Trans>Ambulerande</Trans>
        </Heading>
        {ambulatingShiftsWithContents.length === 0 ? (
          <NoResults
            message={t`Inga ambulerande pass schemalagda för dagen`}
            padding={24}
          />
        ) : (
          <ul className={styles.shiftsList}>
            {ambulatingShiftsWithContents.map((shiftWithContents) => {
              return (
                <ShiftRow
                  key={shiftWithContents.shift.id}
                  shiftWithContents={shiftWithContents}
                  isExpanded={isExpanded}
                  onPendingAddToShift={onPendingAddToShift}
                />
              );
            })}
          </ul>
        )}
      </section>

      <section className={styles.shiftsSection}>
        <Heading className={styles.shiftsListHeader} level="h2">
          <Trans>Övriga</Trans>
        </Heading>
        {otherShiftsWithContents.length === 0 ? (
          <NoResults
            message={t`Inga övriga pass schemalagda för dagen`}
            padding={24}
          />
        ) : (
          <ul className={styles.shiftsList}>
            {otherShiftsWithContents.map((shiftWithContents) => {
              return (
                <ShiftRow
                  key={shiftWithContents.shift.id}
                  shiftWithContents={shiftWithContents}
                  isExpanded={isExpanded}
                  onPendingAddToShift={onPendingAddToShift}
                />
              );
            })}
          </ul>
        )}
      </section>
    </>
  );
};
