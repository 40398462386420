import { useId, useState } from "react";
import styles from "./ExpansionPanel.module.scss";
import CaretDownIcon from "../icons/CaretDownIcon";

type IExpansionPanel = {
  children: string | JSX.Element | JSX.Element[];
  isExpanded?: boolean;
  setIsExpanded?: (prev: boolean) => void;
  trigger: string | JSX.Element | JSX.Element[];
  triggerAriaLabel: string;
};

export const ExpansionPanel = ({
  children,
  isExpanded,
  setIsExpanded,
  trigger,
  triggerAriaLabel,
}: IExpansionPanel) => {
  const triggerId = useId();
  const contentId = useId();
  const [internalIsExpanded, setInternalIsExpanded] = useState(false);
  const [expanded, setExpanded] =
    isExpanded !== undefined && setIsExpanded !== undefined
      ? [isExpanded, setIsExpanded]
      : [internalIsExpanded, setInternalIsExpanded];

  return (
    <article className={styles.expansionPanel}>
      <button
        id={triggerId}
        onClick={() => setExpanded(!expanded)}
        aria-controls={contentId}
        aria-expanded={expanded}
        aria-label={triggerAriaLabel}
        className={styles.trigger}
      >
        {trigger}
        <div className={styles.caret}>
          <CaretDownIcon />
        </div>
      </button>
      {expanded ? (
        <section id={contentId} className={styles.content}>
          {children}
        </section>
      ) : null}
    </article>
  );
};
