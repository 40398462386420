import { type ComponentProps } from "react";
import styles from "./DragAndDrop.module.scss";
import {
  GridList as AriaGridList,
  GridListItem as AriaGridListItem,
} from "react-aria-components";
import clsx from "clsx";

export const MedomaGridList = AriaGridList;
// https://react-spectrum.adobe.com/react-aria/GridList.html#gridlistitem
// A <GridListItem> defines a single option within a <GridListBox>. Use GridList instead of ListBox if there are interactive elements inside (ex button). If the children are not plain text, then the textValue prop must also be set to a plain text representation, which will be used for typeahead in the GridList.
export const MedomaGridListItem = (
  props: ComponentProps<typeof AriaGridListItem> & {
    id: Required<ComponentProps<typeof AriaGridListItem>["id"]>;
    textValue: Required<ComponentProps<typeof AriaGridListItem>["textValue"]>;
  },
) => {
  const { className, id, ...restProps } = props;
  return (
    <AriaGridListItem
      // `key` can cause major breakage if missing: https://github.com/adobe/react-spectrum/issues/5176#issuecomment-1743674682
      key={id}
      id={id}
      className={clsx(styles.option, className)}
      {...restProps}
    />
  );
};
