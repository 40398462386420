import DragHandleDotsIcon from "@components/icons/DragHandleDotsIcon";
import styles from "./DragHandle.module.scss";
import { Button } from "react-aria-components";

export const DragHandle = () => {
  return (
    <div className={styles.container}>
      <Button className={styles.dragHandle} slot="drag">
        <DragHandleDotsIcon />
      </Button>
    </div>
  );
};
