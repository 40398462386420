import { useFormContext } from "react-hook-form";
import Checkbox from "@/components/Checkbox/Checkbox";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import { weekdaySchema, type IWeekday } from "@/api/Activities";
import { t } from "@lingui/macro";
import { weekdayDictionary } from "./weekdaysUtils";
import { useLingui } from "@lingui/react";

export type IWeekdaysPickerFields = {
  weekdays: IWeekday[];
};

export const WeekdaysPicker = () => {
  const { _ } = useLingui();
  const {
    formState: { errors },
    register,
  } = useFormContext<IWeekdaysPickerFields>();
  return (
    <Checkboxes
      errorMessage={errors.weekdays?.message}
      orientation="horizontal"
      legend={t`Upprepa på`}
    >
      {/* Only one Checkbox has to hold additional logic, applies to whole registration. */}
      <Checkbox
        label={{ text: _(weekdayDictionary.Monday.short) }}
        {...register(`weekdays`, {
          required: {
            value: true,
            message: t`Minst en veckodag behöver anges`,
          },
        })}
        value={weekdaySchema.Values.Monday}
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: _(weekdayDictionary.Tuesday.short) }}
        {...register(`weekdays`)}
        value={weekdaySchema.Values.Tuesday}
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: _(weekdayDictionary.Wednesday.short) }}
        {...register(`weekdays`)}
        value={weekdaySchema.Values.Wednesday}
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: _(weekdayDictionary.Thursday.short) }}
        {...register(`weekdays`)}
        value={weekdaySchema.Values.Thursday}
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: _(weekdayDictionary.Friday.short) }}
        {...register(`weekdays`)}
        value={weekdaySchema.Values.Friday}
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: _(weekdayDictionary.Saturday.short) }}
        {...register(`weekdays`)}
        value={weekdaySchema.Values.Saturday}
        visualStyle="framed"
      />
      <Checkbox
        label={{ text: _(weekdayDictionary.Sunday.short) }}
        {...register(`weekdays`)}
        value={weekdaySchema.Values.Sunday}
        visualStyle="framed"
      />
    </Checkboxes>
  );
};
