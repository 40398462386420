import { Dialog } from "@components/Dialog/Dialog";
import { AddActivityForm } from "@/forms/AddActivityForm/AddActivityForm";
import { useNavigateWithPreservedQueryParams } from "@/Utils/useNavigateWithPreservedQueryParams";
import { useCenterBaseUrl } from "@/Utils/useCenterBaseUrl";
import { t } from "@lingui/macro";

export const AddActivityDialog = ({
  redirectPathRelativeToCenterRootOnClose,
}: {
  // onClose navigation is required so that ESC, click outside, and close button all actually close the dialog
  // BUT, we can't control any navigation from within the dialog, so we need to pass the redirect path to this Dialog
  // which in this case is the relative path to the center root
  redirectPathRelativeToCenterRootOnClose: string;
}) => {
  const { navigateWithPreservedQueryParams } =
    useNavigateWithPreservedQueryParams();
  const centerBaseUrl = useCenterBaseUrl();

  return (
    <Dialog
      isOpen={true}
      onClose={() => {
        navigateWithPreservedQueryParams(
          `${centerBaseUrl}/${redirectPathRelativeToCenterRootOnClose}`,
        );
      }}
      title={t`Anpassad aktivitet`}
    >
      <AddActivityForm />
    </Dialog>
  );
};
