import { Tab } from "@components/Tabs";
import NotificationCircle from "@components/NotificationCircle/NotificationCircle";
import { useHasUnreadNotifications } from "@chat/useUnreadNotificationCounts";
import { Trans } from "@lingui/macro";

export const CommunicationTab = ({ patientId }: { patientId: string }) => {
  const hasUnreadNotification = useHasUnreadNotifications({
    roomName: patientId,
  });
  return (
    <Tab to="communication">
      <NotificationCircle isVisible={hasUnreadNotification} placement="right">
        <Trans>Kommunikation</Trans>
      </NotificationCircle>
    </Tab>
  );
};

export default CommunicationTab;
