import { DaySwitcher } from "@/components/DaySwitcher/DaySwitcher";
import styles from "./Shifts.module.scss";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useShiftsViewToolbar } from "./useShiftsViewToolbar";
import StandaloneCheckbox from "@/components/Checkbox/StandaloneCheckbox";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/FeatureFlags";
import { ShiftsSideBar } from "./SideBar/ShiftsSideBar";
import { ShiftsSection } from "./ShiftsSection";
import { t } from "@lingui/macro";
import { ShiftsContextProvider } from "./ShiftsContext";
import { MapProvider } from "react-map-gl";
import clsx from "clsx";
import { useUnplannedOccurrencesAndGroups } from "@/api/Activities";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { ShiftsMap } from "./ShiftsMap";

export type IAllocatableItemType = "group" | "activityOccurrence" | undefined;

const Shifts = () => {
  const { state }: { state?: { day?: string } } = useLocation();
  const [, setSearchParams] = useSearchParams();
  const [
    isExpanded,
    setIsExpanded,
    isMapShown,
    setIsMapShown,
    isToPlanShown,
    setIsToPlanShown,
  ] = useShiftsViewToolbar();
  const [isPendingAddToShift, setIsPendingAddToShift] = useState(false);

  const { data: allocateInShiftsView } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.AllocateInShiftsView,
  );

  const selectedDate = new Date(useSelectedDate());

  const {
    data: unplannedOccurrencesAndGroups,
    isPending,
    isError,
  } = useUnplannedOccurrencesAndGroups(
    selectedDate.toDateString(),
    selectedDate.toDateString(),
  );

  const numberOfUnplannedOccurrences =
    unplannedOccurrencesAndGroups?.length ?? 0;

  // If the state object has a day property, set the search params to that day
  // Passed from CommandCenterContainer.tsx
  useEffect(() => {
    if (state?.day) setSearchParams({ day: state?.day });
  }, [setSearchParams, state]);

  return (
    <ShiftsContextProvider>
      <MapProvider>
        <section className={styles.container}>
          <section className={styles.toolbar}>
            <DaySwitcher size="h2" />
            {allocateInShiftsView ? (
              <StandaloneCheckbox
                label={
                  isError || isPending
                    ? t`Att planera`
                    : t`Att planera (${numberOfUnplannedOccurrences})`
                }
                name="to-plan-view"
                checked={isToPlanShown}
                onChange={(e) => setIsToPlanShown(e.target.checked)}
              />
            ) : null}
            <StandaloneCheckbox
              label={t`Expanderat läge`}
              name="expanded-view"
              checked={isExpanded}
              onChange={(e) => setIsExpanded(e.target.checked)}
            />
            <StandaloneCheckbox
              label={t`Visa karta`}
              name="show-map"
              checked={isMapShown}
              onChange={(e) => setIsMapShown(e.target.checked)}
            />
          </section>

          <div className={styles.contentWrapper}>
            {allocateInShiftsView && isToPlanShown ? (
              <section className={styles.toPlan}>
                <ShiftsSideBar isPendingAddToShift={isPendingAddToShift} />
              </section>
            ) : null}
            <div
              className={clsx(
                styles.shiftsAndMap,
                allocateInShiftsView && isToPlanShown
                  ? ""
                  : styles.sideBarHidden,
              )}
            >
              <div className={styles.shifts}>
                <ShiftsSection
                  isExpanded={isExpanded}
                  onPendingAddToShift={setIsPendingAddToShift}
                />
              </div>
              {isMapShown ? <ShiftsMap /> : null}
            </div>
          </div>
        </section>
      </MapProvider>
    </ShiftsContextProvider>
  );
};

export default Shifts;
