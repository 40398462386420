import type { IWeekday } from "@/api/Activities";
import { ANY_TIME_OF_DAY } from "@/api/Activities";
import type { ITimeSensitivityOption } from "./Scheduling/timeSlotsUtils";
import type { IRecurringOption } from "./Scheduling/recurrenceUtils";
import { recurrenceToNumberDictionary } from "./Scheduling/recurrenceUtils";

export const getTimespan = ({
  isAnyTimeOfDay,
  timeSensitivity,
}: {
  isAnyTimeOfDay: boolean;
  timeSensitivity: ITimeSensitivityOption | undefined;
}) => {
  // If anyTimeOfDay, backend controls the timespan. FE can pass any string atm.
  if (isAnyTimeOfDay) {
    return "23:59:59";
  }

  const timeSensitivityAsNumber = Number(timeSensitivity);

  const [hours, minutes] = [
    Math.floor(timeSensitivityAsNumber),
    Math.floor((timeSensitivityAsNumber % 1) * 60),
  ].map((time) => time.toString().padStart(2, "0"));

  return `${hours}:${minutes}:00`;
};

export const getTimeFields = ({
  isRecurringAsInterval,
  isAnyTimeOfDay,
  timeslots,
  weekdays,
  recurrence,
}: {
  isRecurringAsInterval: boolean;
  isAnyTimeOfDay: boolean;
  timeslots: { time: string }[];
  weekdays: IWeekday[] | undefined;
  recurrence: IRecurringOption;
}) => {
  const timeFields = isRecurringAsInterval
    ? {
        times: isAnyTimeOfDay
          ? ANY_TIME_OF_DAY
          : timeslots.map(({ time }) => time),
        interval: recurrenceToNumberDictionary[recurrence],
      }
    : {
        times: isAnyTimeOfDay
          ? ANY_TIME_OF_DAY
          : timeslots.map(({ time }) => time),
        days: weekdays ?? [],
      };

  return timeFields;
};
