import { createContext, useState, useContext } from "react";
import type { Selection } from "react-aria-components";
import type { IAllocatableItemType } from "./Shifts";
import type { IActivityOccurrenceOrGroup } from "@models/activities";
import type { IVisit } from "@models/visits";

export const ShiftsContext = createContext<{
  draggedKeys: Selection;
  setDraggedKeys: (draggedKeys: Selection) => void;
  currentlyDraggedItemType: IAllocatableItemType;
  setCurrentlyDraggedItemType: (
    currentlyDraggedItemType: IAllocatableItemType,
  ) => void;
  selectedRouteId: string | undefined;
  setSelectedRouteId: (routeId: string | undefined) => void;
  selectedItem: IActivityOccurrenceOrGroup | IVisit | undefined;
  setSelectedItem: (
    selectedItem: IActivityOccurrenceOrGroup | IVisit | undefined,
  ) => void;
}>({
  draggedKeys: new Set(),
  setDraggedKeys: () => {},
  currentlyDraggedItemType: undefined,
  setCurrentlyDraggedItemType: () => {},
  selectedRouteId: undefined,
  setSelectedRouteId: () => {},
  selectedItem: undefined,
  setSelectedItem: () => {},
});

export const ShiftsContextProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [draggedKeys, setDraggedKeys] = useState<Selection>(new Set());
  const [currentlyDraggedItemType, setCurrentlyDraggedItemType] =
    useState<IAllocatableItemType>(undefined);
  const [selectedRouteId, setSelectedRouteId] = useState<string | undefined>(
    undefined,
  );
  const [selectedItem, setSelectedItem] = useState<
    IActivityOccurrenceOrGroup | IVisit | undefined
  >(undefined);

  return (
    <ShiftsContext.Provider
      value={{
        draggedKeys,
        setDraggedKeys,
        currentlyDraggedItemType,
        setCurrentlyDraggedItemType,
        selectedRouteId,
        setSelectedRouteId,
        selectedItem,
        setSelectedItem,
      }}
    >
      {children}
    </ShiftsContext.Provider>
  );
};

export const useShiftsContext = () => useContext(ShiftsContext);
