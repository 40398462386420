import z from "zod";
import { msg } from "@lingui/macro";
import type { MessageDescriptor } from "@lingui/core";

export const blockStatusSchema = z.enum(["draft", "ongoing", "finished"]);
export const blockStatusDictionary: Record<IBlockStatus, MessageDescriptor> = {
  draft: msg`Planerade`,
  ongoing: msg`Pågående`,
  finished: msg`Avslutade`,
};
export type IBlockStatus = z.infer<typeof blockStatusSchema>;
