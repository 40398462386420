import CheckmarkIcon from "@components/icons/CheckmarkIcon";
import CrossIcon from "@components/icons/CrossIcon";
import clsx from "clsx";
import styles from "./TextWithCheckOrCross.module.scss";

export const TextWithCheckOrCross = ({
  condition,
  text,
}: {
  condition: boolean;
  text: string;
}) => {
  return (
    <span className={clsx(styles.iconWithText, styles[condition.toString()])}>
      {condition ? <CheckmarkIcon /> : <CrossIcon />}
      <span>{text}</span>
    </span>
  );
};
