import InputField from "@/components/InputField/InputField";
import { t } from "@lingui/macro";
import { useFormContext } from "react-hook-form";

export const NUMBER_OF_CHARACTERS_ALLOWED_IN_TITLE = 35;

export type ITitleInputFields = {
  title: string;
};

export const TitleInput = ({ suggestions }: { suggestions: string[] }) => {
  const {
    formState: { errors },
    register,
    trigger,
    clearErrors,
  } = useFormContext<ITitleInputFields>();

  return (
    <InputField
      label={t`Titel`}
      errorMessage={errors.title?.message}
      suggestions={suggestions}
      {...register(`title`, {
        required: {
          value: true,
          message: t`Aktiviteten behöver en titel`,
        },
        maxLength: {
          value: NUMBER_OF_CHARACTERS_ALLOWED_IN_TITLE,
          message: t`Namnet får max vara ${NUMBER_OF_CHARACTERS_ALLOWED_IN_TITLE} tecken. Lägg ytterligare text i beskrivningen.`,
        },
        onChange: (e) => {
          // User feedback onChange
          if (e?.currentTarget?.value?.length >= 1) {
            trigger("title");
          } else {
            // ... but don't show annoying errors at this point if the field is empty.
            clearErrors("title");
          }
        },
      })}
    />
  );
};
