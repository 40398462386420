import { deducedError } from "@/Utils/ErrorUtils";
import { activityKeys, updateHidden } from "@/api/Activities";
import { Popover } from "@/components/Popover/Popover";
import { PlainButton } from "@components/Button/Button";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import HiddenIcon from "@components/icons/HiddenIcon";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import styles from "./ActivityIsHiddenPopover.module.scss";
import { routeKeys } from "@/api/Routes";
import * as Sentry from "@sentry/react";
import { shiftKeys } from "@/api/Shifts";
import { t, Trans } from "@lingui/macro";

export const ActivityIsHiddenPopover = ({
  activityId,
  isRecurring,
}: {
  activityId: string;
  isRecurring: boolean;
}) => {
  const queryClient = useQueryClient();

  const {
    mutate: removeHiddenActivityMutation,
    isPending,
    isError,
    error,
  } = useMutation({
    mutationFn: () => updateHidden(activityId, false),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: activityKeys.all }),
        queryClient.invalidateQueries({ queryKey: routeKeys.lists() }),
        queryClient.invalidateQueries({
          queryKey: shiftKeys.listWithContents({}),
        }),
      ]);
    },
  });

  const Content = () => {
    return (
      <>
        <p>
          {isRecurring ? (
            <Trans>Aktivitetsserien visas inte för patienten</Trans>
          ) : (
            <Trans>Aktiviteten visas inte för patienten</Trans>
          )}
        </p>
        <PlainButton
          disabled={isPending}
          onClick={() => removeHiddenActivityMutation()}
        >
          {isRecurring ? (
            <Trans>Gör aktivitetsserien synlig</Trans>
          ) : (
            <Trans>Gör aktiviteten synlig</Trans>
          )}
        </PlainButton>
      </>
    );
  };

  const Status = () => {
    if (isPending) {
      return <Loading message={t`Ändrar synlighet`} />;
    }

    if (isError) {
      Sentry.captureException(error);
      const seriesOrSingleActivity = isRecurring
        ? t`aktivitetsserien`
        : t`aktiviteten`;
      return (
        <ErrorMessage
          message={`${t`Gick inte att ändra ${
            seriesOrSingleActivity
          } från "dold" till "synlig".`} ${deducedError(error)}`}
        />
      );
    }
  };

  return (
    <Popover
      trigger={{
        icon: <HiddenIcon />,
        ariaLabel: t`Visa information om synlighet`,
      }}
    >
      <div className={styles.content}>
        <Status />
        <Content />
      </div>
    </Popover>
  );
};
