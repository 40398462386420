import type { MatrixClient, MatrixEvent } from "matrix-js-sdk";
import { RoomEvent } from "matrix-js-sdk";
import { useEffect } from "react";

const isRelevantEvent = (event: MatrixEvent, userId: string | null) => {
  const senderIsMe = event?.getSender() === userId;
  const senderIsUnknown = event.sender?.name === undefined;

  const isRelevantSender = !senderIsMe && !senderIsUnknown;

  // When performing initial sync, we get a lot of old events.
  // To distinguish between old events and new events, we only consider those that we received in the last 5 seconds.
  // This is a heuristic, but it works well enough.
  const FIVE_SECONDS = 5000;
  const isRecentEvent = event.getLocalAge() < FIVE_SECONDS;

  return isRelevantSender && isRecentEvent;
};

export const useChatNotifications = (
  matrixClient: MatrixClient | null,
  shouldAlert: (senderId: string | undefined) => boolean,
  onAlert: (senderId: string | undefined) => void,
) => {
  useEffect(() => {
    if (matrixClient === null) {
      return;
    }

    // Use a named callback to share the same reference when unsubscribing
    const considerNotifying = (event: MatrixEvent) => {
      if (!isRelevantEvent(event, matrixClient.getUserId())) return;
      if (!shouldAlert(event.sender?.name)) return;

      onAlert(event.sender?.name);
    };

    matrixClient.on(RoomEvent.Timeline, considerNotifying);
    return () => {
      matrixClient.off(RoomEvent.Timeline, considerNotifying);
    };
  }, [matrixClient, shouldAlert, onAlert]);
};
