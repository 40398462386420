import { FilledButton, PlainButton } from "../Button/Button";
import CheckCircleIcon from "../icons/CheckCircleIcon";
import CircledCrossIcon from "../icons/CircledCrossIcon";
import InformationIcon from "../icons/InformationIcon";
import WarningTriangleIcon from "../icons/WarningTriangleIcon";
import styles from "./InlineAlert.module.scss";
import clsx from "clsx";

type IInlineAlert = {
  type: "info" | "success" | "warning" | "error";
  title: string;
  message?: string;
  actions?: {
    label: string;
    onClick: () => void;
    isPrimary?: boolean;
  }[];
  placement?: "center" | "fill" | "hug";
};

const RegularContent = ({
  title,
  message,
  actions,
}: Pick<IInlineAlert, "title" | "message" | "actions">) => {
  return (
    <div className={styles.regularContent}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        {message ? <p>{message}</p> : null}
      </div>
      {actions ? (
        <div className={styles.actions}>
          {actions.map((action) =>
            action.isPrimary ? (
              <FilledButton key={action.label} onClick={action.onClick}>
                {action.label}
              </FilledButton>
            ) : (
              <PlainButton key={action.label} onClick={action.onClick}>
                {action.label}
              </PlainButton>
            ),
          )}
        </div>
      ) : null}
    </div>
  );
};

const TitleAndInlineActionsContent = ({
  title,
  actions,
}: Pick<IInlineAlert, "title" | "actions">) => {
  return (
    <div className={styles.titleAndInlineActionsContent}>
      <div className={styles.title}>{title}</div>
      {actions ? (
        <>
          {actions.map((action) =>
            action.isPrimary ? (
              <FilledButton key={action.label} onClick={action.onClick}>
                {action.label}
              </FilledButton>
            ) : (
              <PlainButton key={action.label} onClick={action.onClick}>
                {action.label}
              </PlainButton>
            ),
          )}
        </>
      ) : null}
    </div>
  );
};

export const InlineAlert = ({
  type,
  title,
  message,
  actions,
  placement = "center",
}: IInlineAlert) => {
  const renderIcon = () => {
    switch (type) {
      case "info":
        return <InformationIcon />;
      case "success":
        return <CheckCircleIcon />;
      case "warning":
        return <WarningTriangleIcon />;
      case "error":
        return <CircledCrossIcon />;
    }
  };

  // Heuristic to determine if the alert is best represented as a title with inline actions.
  // Feel free to change if needed, keeping existing use cases (search for patient) in mind.
  const bestRepresentedAsTitleAndInlineActions = actions && !message;

  return (
    <dialog
      open
      className={clsx([
        styles.inlineAlert,
        styles[`type-${type}`],
        styles[`placement-${placement}`],
      ])}
    >
      <div className={styles.icon}>{renderIcon()}</div>
      {bestRepresentedAsTitleAndInlineActions ? (
        <TitleAndInlineActionsContent title={title} actions={actions} />
      ) : (
        <RegularContent title={title} message={message} actions={actions} />
      )}
    </dialog>
  );
};
