import { InteractionRequiredAuthError } from "@azure/msal-browser";
import type { InternalAxiosRequestConfig } from "axios";
import axios from "axios";
import { sub } from "date-fns";
import {
  API_CLIENT_ID,
  AUTH_API_URL,
  GRAPH_API_URL,
  LOGISTICS_API_URL,
  MAPBOX_API_URL,
  NOTIFICATION_API_URL,
  PATIENT_API_CLIENT_ID,
  PATIENT_API_URL,
  publicClientApplication,
  userManager,
} from "../Utils/EnvUtils";
import { User } from "oidc-client-ts";
import * as Sentry from "@sentry/react";

export const scopes = {
  accessGraphApiUserRead: "User.Read",
  accessMedomaBackendAsUser: `api://${API_CLIENT_ID}/access_as_user`,
  accessPatientApi: `api://${PATIENT_API_CLIENT_ID}/access_as_user`,
};

const logisticsApi = axios.create({
  baseURL: LOGISTICS_API_URL,
});

const patientApi = axios.create({
  baseURL: PATIENT_API_URL,
});

const graphApi = axios.create({
  baseURL: GRAPH_API_URL,
});

const notificationApi = axios.create({
  baseURL: NOTIFICATION_API_URL,
});

const injectBearerToken = (
  config: InternalAxiosRequestConfig,
  token: string | undefined,
) => {
  if (config && config.headers) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
};

const aadRequestHandler = async (
  config: InternalAxiosRequestConfig,
  scopes: [string],
) => {
  // Don't look for access token in test scenario.
  if (import.meta.env.MODE === "test") return config;
  const token = await getAccessToken(scopes);
  return injectBearerToken(config, token);
};

export const getAccessToken = async (scopes: [string]) => {
  // If the user acquired the token through redirection, we handle that here.
  const redirectResponse =
    await publicClientApplication.handleRedirectPromise();
  if (
    redirectResponse !== null &&
    // Check that we are looking at a token with (some of) the correct scopes attached.
    redirectResponse.scopes.includes(scopes[0]) &&
    // Ensure expiresOn exists, but more importantly...
    redirectResponse.expiresOn !== null &&
    // ...ensure that there is at least 5 minutes left of validity on the access token.
    redirectResponse.expiresOn > sub(new Date(), { minutes: 5 })
  ) {
    return redirectResponse.accessToken;
  } else {
    // Otherwise, construct an appropriate request for an access token.
    const accessTokenRequest = {
      scopes,
      account: publicClientApplication.getAllAccounts()[0],
      redirectUri: `${window.location.origin}/login`,
    };

    // No account logged in? Just redirect at once.
    if (publicClientApplication.getAllAccounts().length === 0) {
      publicClientApplication.acquireTokenRedirect(accessTokenRequest);
    }

    try {
      const accessTokenResponse =
        await publicClientApplication.acquireTokenSilent(accessTokenRequest);
      return accessTokenResponse.accessToken;
    } catch (error) {
      // Not possible to get token because of interaction required? Redirect.
      if (error instanceof InteractionRequiredAuthError) {
        publicClientApplication.acquireTokenRedirect(accessTokenRequest);
      }
    }
  }
};

logisticsApi.interceptors.request.use((config) => {
  // Don't intercept in test scenario.
  if (import.meta.env.MODE === "test") return config;

  // @ts-expect-error `meta` does not usually exist.
  config.meta = config.meta || {};
  // @ts-expect-error `meta` does not usually exist.
  config.meta.requestStartedAt = new Date().getTime();

  return aadRequestHandler(config, [scopes.accessMedomaBackendAsUser]);
});
logisticsApi.interceptors.response.use(
  (response) => {
    // Don't intercept in test scenario.
    if (import.meta.env.MODE === "test") return response;
    if (
      // @ts-expect-error `meta` does not usually exist.
      response.config.meta?.requestStartedAt &&
      // @ts-expect-error `meta` does not usually exist.
      new Date().getTime() - response.config.meta?.requestStartedAt > 15000 && // If more than 15 seconds have passed
      // Ignore SSE requests since they are supposed to take long.
      !response.config.url?.includes("notification")
    ) {
      Sentry.captureException(
        // @ts-expect-error `meta` does not usually exist.
        `Extremely slow network request. Request to: ${response.config.url} took ${(new Date().getTime() - response.config.meta.requestStartedAt) / 1000} seconds.`,
      );
    }
    // For successful responses, just return the response
    return response;
  },
  (error) => {
    // Don't intercept in test scenario.
    if (import.meta.env.MODE === "test") return Promise.reject(error);
    if (error && error.response && error.response.status === 0) {
      Sentry.captureException(
        `Network request failed. Request failed with status 0.`,
      );
    }
    // Always reject the promise to ensure the error is handled by the calling code
    return Promise.reject(error);
  },
);
patientApi.interceptors.request.use((config) =>
  aadRequestHandler(config, [scopes.accessPatientApi]),
);
graphApi.interceptors.request.use((config) =>
  aadRequestHandler(config, [scopes.accessGraphApiUserRead]),
);
notificationApi.interceptors.request.use((config) =>
  aadRequestHandler(config, [scopes.accessMedomaBackendAsUser]),
);

const mapboxApi = axios.create({
  baseURL: MAPBOX_API_URL,
});

const getMapboxToken = () =>
  "pk.eyJ1IjoibWVkb21hIiwiYSI6ImNsN2Z6c2J0bTAwOHozd25xM3JlNzZ6bjUifQ.v2XsEAYwGo00Vk0XZteAZQ";

mapboxApi.interceptors.request.use((config) => {
  // Don't look for access token in test scenario.
  if (import.meta.env.MODE === "test") return config;
  const token = getMapboxToken();
  if (config && config.url) {
    config.url = config.url.concat(`&access_token=${token}`);
  }
  return config;
});

export const getIdentityServerUser = () => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${AUTH_API_URL}:medoma-center`,
  );
  if (!oidcStorage) {
    return null;
  }

  const user = User.fromStorageString(oidcStorage);
  if (user?.expired) {
    userManager.signinRedirect({
      acr_values: "idp:aad",
      scope: "IdentityServerApi create.otp",
      state: window.location.pathname,
    });
  }
  return user;
};

export { graphApi, mapboxApi, patientApi, logisticsApi, notificationApi };
