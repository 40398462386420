import { patientKeys, updateName } from "@/api/Patients";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { FilledButton } from "@components/Button/Button";
import Form from "@/components/Form/Form";
import { Loading } from "@components/Loading/Loading";
import { deducedError } from "@/Utils/ErrorUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import InputField from "@/components/InputField/InputField";
import { t, Trans } from "@lingui/macro";

export const EditName = ({
  currentName,
  onSuccess,
  patientId,
}: {
  currentName: string;
  onSuccess: () => void;
  patientId: string;
}) => {
  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      name: currentName,
    },
  });

  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (name: string) => updateName(patientId, name),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: patientKeys.detail(patientId),
      });
      onSuccess();
    },
  });

  return (
    <Form
      onSubmit={handleSubmit((formData) => {
        mutate(formData.name);
      })}
    >
      {isPending || isSuccess ? (
        <Loading message={t`Ändrar namn`} />
      ) : (
        <>
          {errors.root?.server?.message && !isDirty ? (
            <ErrorMessage message={errors.root.server.message} />
          ) : undefined}
          <Form.Row>
            <InputField
              label={t`Namn`}
              errorMessage={errors.name?.message}
              {...register("name", {
                required: {
                  value: true,
                  message: t`Namn behövs`,
                },
              })}
              width="fit"
            />
          </Form.Row>
          <FilledButton type="submit">
            <Trans>Spara ändringar</Trans>
          </FilledButton>
        </>
      )}
    </Form>
  );
};
