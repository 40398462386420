import type { IAddActivityFormData } from "@/forms/AddActivityForm/AddActivityForm";
import {
  activityFrequencySchema,
  recurringOptionSchema,
} from "@/forms/AddActivityForm/Scheduling/recurrenceUtils";
import { categorySchema, timeOfDaySchema } from "@models/activities";
import { medicalCompetenceSchema } from "@models/shifts";
import { msg } from "@lingui/macro";
import type { MessageDescriptor } from "@lingui/core";

export type IBaseTemplate = Pick<
  IAddActivityFormData,
  | "category"
  | "doubleStaffing"
  | "duration"
  | "recurrence"
  | "frequency"
  | "recurrencesPerDay"
  | "requiredCompetences"
  | "timeCategory"
  | "timeSensitivity"
  | "timeslots"
  | "hidden"
  | "weekdays"
  | "measurements"
> & {
  templateOptions: {
    showInList: boolean;
  };
  templateId: string;
  templateRevision: number;
  title: MessageDescriptor;
  description: MessageDescriptor | null;
};

type IAnyTimeOfDayTemplate = Omit<
  IBaseTemplate,
  "timeslots" | "timeSensitivity" | "recurrencesPerDay"
>;

// Must be a subset of IAddActivityFormData
export type ITemplate = IBaseTemplate | IAnyTimeOfDayTemplate;

export type ITemplateKey =
  | "bloodSample"
  | "replaceAlarm"
  | "deliverMaterialBox"
  | "retrieveMaterialBox"
  | "retrieveTechBox"
  | "handoverTreatmentMessage"
  | "patientSurvey"
  | "returnKey"
  | "keyQuittance"
  | "removeCatheter"
  | "checkCatheter"
  | "riskAssessment"
  | "deliverAlarm"
  | "medicationWalkthrough"
  | "replaceMaterialBox"
  | "replaceAlarm"
  | "round"
  | "patientVitalsMorning"
  | "fluidMeasurement"
  | "patientVitalsEvening"
  | "retrieveAlarmClock"
  | "deliverMedication"
  | "connectMedicationPump"
  | "medicationPumpReplacement"
  | "medicationPumpCheck"
  | "takeMedicineOnce"
  | "takeMedicineTwice"
  | "exchangePVK"
  | "takeMedicineThreeTimes"
  | "ownMeasurementBloodSugar"
  | "takeMedicineFourTimes"
  | "ownMeasurementWeight"
  | "intravenousMedication"
  | "prepareDosette"
  | "redoCentral"
  | "medicationSubcutaneousAdministration"
  | "removeMedicationPump"
  | "deliverFood"
  | "takeMedicineFiveTimes"
  | "takeMedicineSixTimes"
  | "handoverMedicationList";

export const templateSuggestions = [
  msg`Blodprov`,
  msg`Byte av pump`,
  msg`Byte larmklocka`,
  msg`Byte materiallåda`,
  msg`Byte PVK - perfier infart`,
  msg`Dosettförberedelse`,
  msg`Dra infarter`,
  msg`Egen mätning blodsocker`,
  msg`Egen mätning kväll`,
  msg`Egen mätning morgon`,
  msg`Egen mätning vikt`,
  msg`Hämtning larmklocka`,
  msg`Hämtning materiallåda`,
  msg`Hämtning tekniklåda`,
  msg`Kontroll infart`,
  msg`Koppla pump`,
  msg`Koppla bort pump`,
  msg`Leverans larmklocka`,
  msg`Leverans läkemedel`,
  msg`Leverans mat`,
  msg`Leverans materiallåda`,
  msg`Läkemedel subkutan injektion`,
  msg`Läkemedel intravenöst`,
  msg`Läkemedelsgenomgång`,
  msg`Nyckelkvittens`,
  msg`Omläggning central infart`,
  msg`Patientenkät`,
  msg`Pumpkontroll digital`,
  msg`Riskbedömning`,
  msg`Rond`,
  msg`Vätske- och urinmätning`,
  msg`Återlämning nyckel`,
  msg`Överlämning behandlingsmeddelande`,
  msg`Överlämning läkemedelslista`,
];

export const templates: Record<ITemplateKey, ITemplate> = {
  bloodSample: {
    title: msg`Blodprov`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "08:00" }],
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "1447d006-d953-428c-92f6-666c40c1a58f",
    templateRevision: 1,
  },
  medicationPumpReplacement: {
    title: msg`Byte av pump`,
    category: categorySchema.Values.HomeVisit,
    description: msg`Kom ihåg att ändra tiden till 24 timmar efter att pumpen kopplats och ta sedan bort "dold för patienten"`,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "00:00" }],
    timeSensitivity: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "f6685fed-5dff-4d53-aa00-912b5a1928b9",
    templateRevision: 2,
  },
  replaceAlarm: {
    title: msg`Byte larmklocka`,
    category: categorySchema.Values.HomeVisit,
    description: msg`Fyll i klockans larmnummer under Patientinformation`,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.every3rdDay,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "37b3f115-cc7f-416a-bd66-5d7582870196",
    templateRevision: 1,
  },
  replaceMaterialBox: {
    title: msg`Byte materiallåda`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.every5thDay,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "7ed1e9e3-5305-4c7b-a620-6df2223fe060",
    templateRevision: 1,
  },
  exchangePVK: {
    title: msg`Byte PVK - perfier infart`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.every3rdDay,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "b16dcdce-0b23-4188-9564-e711caa53815",
    templateRevision: 1,
  },
  prepareDosette: {
    title: msg`Dosettförberedelse`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "3f976242-4263-4191-b253-bf4907bf8e4a",
    templateRevision: 1,
  },
  removeCatheter: {
    title: msg`Dra infarter`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "b793e53f-2df0-4c14-84eb-e4537844710b",
    templateRevision: 1,
  },
  ownMeasurementBloodSugar: {
    title: msg`Egen mätning blodsocker`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "7",
    timeslots: [
      { time: "07:30" },
      { time: "10:00" },
      { time: "11:30" },
      { time: "14:00" },
      { time: "18:00" },
      { time: "20:00" },
      { time: "23:00" },
    ],
    timeSensitivity: "0.5",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodGlucose"],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "c41bf40e-7467-426e-b331-5820d02259f5",
    templateRevision: 1,
  },
  patientVitalsEvening: {
    title: msg`Egen mätning kväll`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "17:30" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodPressure", "pulse", "saturation", "temperature"],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "bc00f686-6145-446d-9997-0ac97b50a61c",
    templateRevision: 1,
  },
  patientVitalsMorning: {
    title: msg`Egen mätning morgon`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "06:30" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodPressure", "pulse", "saturation", "temperature"],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "e93f17d3-6f2b-4d61-9ff0-6aaa7fd61c42",
    templateRevision: 1,
  },
  ownMeasurementWeight: {
    title: msg`Egen mätning vikt`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "08:00" }],
    timeSensitivity: "0.5",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["weight"],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "b8399642-d386-4533-9cf7-8ea282d704df",
    templateRevision: 1,
  },
  retrieveAlarmClock: {
    title: msg`Hämtning larmklocka`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "5aff2acd-4c59-4be3-96a6-6316463afcf8",
    templateRevision: 1,
  },
  retrieveMaterialBox: {
    title: msg`Hämtning materiallåda`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "2a6f491f-6b38-4b44-849d-c26600a5050a",
    templateRevision: 1,
  },
  retrieveTechBox: {
    title: msg`Hämtning tekniklåda`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "1984b33a-605b-42fc-bb91-b65b90819736",
    templateRevision: 1,
  },
  checkCatheter: {
    title: msg`Kontroll infart`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "601c001c-8d87-435d-8200-9d3ebba2930b",
    templateRevision: 1,
  },
  connectMedicationPump: {
    title: msg`Koppla pump`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }], // The user must choose a time
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "7cede509-6b71-4bd9-8ea9-cb8744278ea5",
    templateRevision: 1,
  },
  removeMedicationPump: {
    title: msg`Koppla bort pump`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "c3d50d41-ecfd-4b8c-9bfd-28eb82ed18e7",
    templateRevision: 1,
  },
  deliverAlarm: {
    title: msg`Leverans larmklocka`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "0784cc93-18cb-4f3b-a2cf-c3a6b6d0baca",
    templateRevision: 1,
  },
  deliverMedication: {
    title: msg`Leverans läkemedel`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.custom,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "aead9f16-0e2f-4235-bf29-e2329d8893d8",
    templateRevision: 1,
  },
  deliverFood: {
    title: msg`Leverans mat`,
    category: categorySchema.Values.HomeVisit,
    description: msg`Fråga patient om önskemål och eventuella allergier`,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.every2ndDay,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "4999baee-7173-4158-869d-4c7efbd7aa0b",
    templateRevision: 1,
  },
  deliverMaterialBox: {
    title: msg`Leverans materiallåda`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "58388aca-3f3b-41ea-8af3-dee1addde183",
    templateRevision: 1,
  },
  intravenousMedication: {
    title: msg`Läkemedel intravenöst`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "3",
    timeslots: [{ time: "00:00" }, { time: "08:00" }, { time: "16:00" }],
    timeSensitivity: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "7fa267d2-fba3-490e-a529-88e0c523d55c",
    templateRevision: 1,
  },
  medicationSubcutaneousAdministration: {
    title: msg`Läkemedel subkutan injektion`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }], // The user must choose a time
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "4fb46db4-c9c7-4217-a0a3-8c77560986ba",
    templateRevision: 1,
  },
  medicationWalkthrough: {
    title: msg`Läkemedelsgenomgång`,
    category: categorySchema.Values.VideoCall,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.MedicalDoctor],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "27254a85-296e-4320-9bb4-00fe8e1b06f7",
    templateRevision: 1,
  },
  keyQuittance: {
    title: msg`Nyckelkvittens`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "cec340d5-f295-477a-a168-0de94918b3d3",
    templateRevision: 1,
  },
  redoCentral: {
    title: msg`Omläggning central infart`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.custom,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "5db951ec-08a1-409d-8e4f-d53bc43adede",
    templateRevision: 1,
  },
  patientSurvey: {
    title: msg`Patientenkät`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "77936b25-936f-4963-95da-e316085facb6",
    templateRevision: 1,
  },
  medicationPumpCheck: {
    title: msg`Pumpkontroll digital`,
    category: categorySchema.Values.VideoCall,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "18:00" }],
    timeSensitivity: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "3bf79eeb-8e62-4ee3-b751-b5ee20da2eec",
    templateRevision: 1,
  },
  riskAssessment: {
    title: msg`Riskbedömning`,
    category: categorySchema.Values.HomeVisit,
    description: msg`För in i Cosmic`,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "7eb2dcd9-f6cc-4926-95cd-28a46211bf14",
    templateRevision: 1,
  },
  round: {
    title: msg`Rond`,
    category: categorySchema.Values.VideoCall,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "2",
    requiredCompetences: [
      medicalCompetenceSchema.Values.NursePractitioner,
      medicalCompetenceSchema.Values.MedicalDoctor,
    ],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "542a2317-ca1c-4e29-81eb-cb6266aa4632",
    templateRevision: 1,
  },
  takeMedicineOnce: {
    title: msg`Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "08:00" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "b20b0c3a-0376-43c7-a2e6-222fad3fb528",
    templateRevision: 1,
  },
  takeMedicineTwice: {
    title: msg`Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "2",
    timeslots: [{ time: "08:00" }, { time: "20:00" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "b6ed1e73-0f67-411e-bfbe-f416bbbda18b",
    templateRevision: 1,
  },
  takeMedicineThreeTimes: {
    title: msg`Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "3",
    timeslots: [{ time: "08:00" }, { time: "14:00" }, { time: "20:00" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "b2b8565b-655f-4a08-9b24-405902529162",
    templateRevision: 1,
  },
  takeMedicineFourTimes: {
    title: msg`Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "4",
    timeslots: [
      { time: "08:00" },
      { time: "14:00" },
      { time: "20:00" },
      { time: "22:00" },
    ],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "6ce1070f-cdd9-4d28-9506-c26f7dba6ca7",
    templateRevision: 1,
  },
  takeMedicineFiveTimes: {
    title: msg`Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "5",
    timeslots: [
      { time: "08:00" },
      { time: "13:00" },
      { time: "18:00" },
      { time: "20:00" },
      { time: "22:00" },
    ],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "3eec829e-29ff-4156-9da4-227ce80bf804",
    templateRevision: 1,
  },
  takeMedicineSixTimes: {
    title: msg`Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "6",
    timeslots: [
      { time: "06:00" },
      { time: "10:00" },
      { time: "14:00" },
      { time: "18:00" },
      { time: "20:00" },
      { time: "22:00" },
    ],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "12647e10-7e15-4c90-82da-ce86d96da994",
    templateRevision: 1,
  },
  fluidMeasurement: {
    title: msg`Vätske- och urinmätning`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "06:30" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
    },
    templateId: "f6e41839-b658-4f8c-b5f6-275122dae807",
    templateRevision: 1,
  },
  returnKey: {
    title: msg`Återlämning nyckel`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "67a1446f-e14c-42b9-b379-6a2d7d5f9985",
    templateRevision: 1,
  },
  handoverTreatmentMessage: {
    title: msg`Överlämning behandlingsmeddelande`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "64b29fd4-d2fe-4fa1-a4d7-a8ca36ad913a",
    templateRevision: 1,
  },
  handoverMedicationList: {
    title: msg`Överlämning läkemedelslista`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
    },
    templateId: "a168d742-71c3-4ba0-90b6-7287425a63af",
    templateRevision: 1,
  },
};
