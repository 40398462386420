import { useState } from "react";

export const useCurrentlyEditing = () => {
  const [currentlyEditing, setCurrentlyEditing] = useState<
    | "time"
    | "title"
    | "description"
    | "schedule"
    | "requiredCompetences"
    | "doubleStaffing"
    | "hidden"
    | "measurements"
    | "move"
    | null
  >(null);

  return { currentlyEditing, setCurrentlyEditing };
};
