import { useFormContext } from "react-hook-form";
import type { IWeekdaysPickerFields } from "./WeekdaysPicker";
import { WeekdaysPicker } from "./WeekdaysPicker";
import type { IRecurrencePickerFields } from "./RecurrencePicker";
import { RecurrencePicker } from "./RecurrencePicker";
import { recurringOptionSchema } from "./recurrenceUtils";
import type { ITimeSlotsPickerFields } from "./TimeSlotsPicker";
import { TimeSlotsPicker } from "./TimeSlotsPicker";
import type { IDateInputFields } from "./DateInput";
import { DateInput } from "./DateInput";
import { useEffect } from "react";

export type ISchedulingFields = IRecurrencePickerFields &
  IWeekdaysPickerFields &
  IDateInputFields &
  ITimeSlotsPickerFields;

export const Scheduling = () => {
  const { unregister, watch } = useFormContext<ISchedulingFields>();

  const isRecurring =
    watch("recurrence") !== recurringOptionSchema.Values.never;
  const isRecurringOnWeekdays =
    watch("recurrence") === recurringOptionSchema.Values.custom;

  useEffect(() => {
    if (!isRecurringOnWeekdays) {
      unregister("weekdays");
    }
  }, [isRecurringOnWeekdays, unregister]);

  return (
    <>
      <RecurrencePicker />
      {isRecurringOnWeekdays ? <WeekdaysPicker /> : <></>}
      <DateInput hasEndDate={isRecurring} />
      <TimeSlotsPicker isRecurring={isRecurring} />
    </>
  );
};
