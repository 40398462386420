import RadioButton from "@/components/RadioButton/RadioButton";
import RadioButtons from "@/components/RadioButton/RadioButtons";
import { useFormContext } from "react-hook-form";
import {
  type IActivityFrequencySchema,
  type IRecurringOption,
  activityFrequencyDictionary,
  activityFrequencySchema,
} from "./recurrenceUtils";
import { recurringDictionary, recurringOptionSchema } from "./recurrenceUtils";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useEffect } from "react";

export type IRecurrencePickerFields = {
  recurrence: IRecurringOption;
  frequency: IActivityFrequencySchema;
};

export const RecurrencePicker = ({
  onlyRecurringOptions = false,
}: {
  onlyRecurringOptions?: boolean;
}) => {
  const { _ } = useLingui();
  const {
    formState: { errors },
    register,
    watch,
    setValue,
  } = useFormContext<IRecurrencePickerFields>();

  const selectedRecurring = watch("frequency");
  const isRecurring =
    selectedRecurring === activityFrequencySchema.Values.recurring;

  // Set recurrence to 'never' when 'recurringType' is 'oneTime'
  useEffect(() => {
    if (selectedRecurring === activityFrequencySchema.Values.oneTime) {
      setValue("recurrence", recurringOptionSchema.Values.never);
    }
  }, [selectedRecurring, setValue]);

  return (
    <>
      {onlyRecurringOptions ? (
        <></>
      ) : (
        <RadioButtons
          legend={t`Frekvens`}
          errorMessage={errors.frequency?.message}
          orientation="horizontal"
        >
          <RadioButton
            label={{ text: _(activityFrequencyDictionary.oneTime) }}
            {...register("frequency", {
              required: {
                value: true,
                message: t`Upprepningsmönster måste sättas`,
              },
            })}
            value={activityFrequencySchema.Values.oneTime}
            visualStyle="framed"
          />
          <RadioButton
            label={{ text: _(activityFrequencyDictionary.recurring) }}
            {...register("frequency")}
            value={activityFrequencySchema.Values.recurring}
            visualStyle="framed"
          />
        </RadioButtons>
      )}

      {(isRecurring || onlyRecurringOptions) && (
        <RadioButtons
          errorMessage={errors.recurrence?.message}
          orientation="horizontal"
          legend={t`Upprepning`}
        >
          <RadioButton
            label={{ text: _(recurringDictionary.everyDay) }}
            {...register("recurrence", {
              required: {
                value: true,
                message: t`Upprepningsmönster måste sättas`,
              },
            })}
            value={recurringOptionSchema.Values.everyDay}
            visualStyle="framed"
          />
          <RadioButton
            label={{ text: _(recurringDictionary.every2ndDay) }}
            {...register("recurrence")}
            value={recurringOptionSchema.Values.every2ndDay}
            visualStyle="framed"
          />
          <RadioButton
            label={{ text: _(recurringDictionary.every3rdDay) }}
            {...register("recurrence")}
            value={recurringOptionSchema.Values.every3rdDay}
            visualStyle="framed"
          />
          <RadioButton
            label={{ text: _(recurringDictionary.every5thDay) }}
            {...register("recurrence")}
            value={recurringOptionSchema.Values.every5thDay}
            visualStyle="framed"
          />
          <RadioButton
            label={{ text: _(recurringDictionary.custom) }}
            {...register("recurrence")}
            value={recurringOptionSchema.Values.custom}
            visualStyle="framed"
          />
        </RadioButtons>
      )}
    </>
  );
};
