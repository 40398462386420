import * as React from "react";
const SvgViewOff = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", height: "1em", width: "1em", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("style", null, "\n          .a {\n          fill: none;\n          stroke:var(--icon-color, currentColor);\n          stroke-linecap: round;\n          stroke-linejoin: round;\n          stroke-width: 1.5px;\n          }\n        ")), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("line", { x1: 2.78, y1: 21, x2: 21.53, y2: 3, style: {
  fill: "none",
  stroke: "var(--icon-color, currentColor)",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9,19.05a9.91,9.91,0,0,0,3,.45c4.1.07,8.26-2.81,10.82-5.64a1.65,1.65,0,0,0,0-2.22,20.06,20.06,0,0,0-3.07-2.76", style: {
  fill: "none",
  stroke: "var(--icon-color, currentColor)",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M17.09,7.27A11.31,11.31,0,0,0,12,6C8,5.93,3.8,8.75,1.18,11.64a1.65,1.65,0,0,0,0,2.22,20,20,0,0,0,4.93,4", style: {
  fill: "none",
  stroke: "var(--icon-color, currentColor)",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9,15.07a3.85,3.85,0,0,1,5.5-5.28", style: {
  fill: "none",
  stroke: "var(--icon-color, currentColor)",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M15.75,12.75h0A3.75,3.75,0,0,1,12,16.5", style: {
  fill: "none",
  stroke: "var(--icon-color, currentColor)",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "1.5px"
} })));
export default SvgViewOff;
