import { UnstyledLink } from "@components/Link/Link";
import styles from "./Chat.module.scss";
import { Outlet, useParams } from "react-router-dom";
import clsx from "clsx";
import type { IEmployee } from "@models/shifts";
import Portrait from "@components/Portrait/Portrait";
import { Heading } from "@components/Heading/Heading";
import { CompetenceChip } from "@/components/Chips/CompetenceChip";
import { useScheduledCenterShifts, useScheduledShifts } from "@/api/Shifts";
import {
  useRoomNamesWithUnreadNotifications,
  useUnreadNotificationCounts,
} from "@chat/useUnreadNotificationCounts";
import NotificationCircle from "@components/NotificationCircle/NotificationCircle";
import { useEmployees } from "@/api/Employee";
import { ROOM_ALIAS_PREFIX_EMPLOYEE } from "@chat/matrixClient";
import { Loading } from "@components/Loading/Loading";
import NoResults from "@/components/NoResults/NoResults";
import { startOfDay } from "date-fns";
import { t, Trans } from "@lingui/macro";

const useEmployeesWithUnreadNotifications = (
  allEmployees: IEmployee[] | undefined,
) => {
  const roomNamesWithUnreadNotifications =
    useRoomNamesWithUnreadNotifications();
  return roomNamesWithUnreadNotifications
    .filter((name) => name.startsWith(ROOM_ALIAS_PREFIX_EMPLOYEE))
    .map((roomName) => roomName.slice(ROOM_ALIAS_PREFIX_EMPLOYEE.length))
    .map((employeeId) =>
      allEmployees?.find((employee) => employee.id.toString() === employeeId),
    )
    .filter((maybeEmployee) => maybeEmployee !== undefined) as IEmployee[];
};

const sortOnFirstName = (
  a: Pick<IEmployee, "firstName">,
  b: Pick<IEmployee, "firstName">,
) => {
  return a.firstName.localeCompare(b.firstName);
};

const ChatListItemLink = ({
  employee,
  size,
}: {
  employee: IEmployee;
  size?: "compact";
}) => {
  const { employeeId: employeeIdFromParams } = useParams();
  const { id, imageURL, firstName, lastName, competence } = employee;
  const idAsString = id.toString();
  const unreadNotificationsCount = useUnreadNotificationCounts({
    roomName: `${ROOM_ALIAS_PREFIX_EMPLOYEE}${idAsString}`,
  });
  const hasUnreadNotifications =
    unreadNotificationsCount !== undefined && unreadNotificationsCount > 0;

  return (
    <UnstyledLink to={idAsString}>
      <div
        className={clsx([
          styles.chatListItemLink,
          idAsString === employeeIdFromParams && styles.active,
        ])}
      >
        <NotificationCircle
          isVisible={hasUnreadNotifications}
          number={unreadNotificationsCount}
          placement="top-right"
        >
          <div className={styles.chatListItemLinkContent}>
            <Portrait
              name={`${firstName} ${lastName}`}
              photoUrl={imageURL}
              size={size === "compact" ? "small" : "large"}
            />
            <div className={styles.details}>
              <div className={styles.row1}>
                <Heading level="h2" size="h3">
                  {firstName} {lastName}
                </Heading>
                <CompetenceChip competence={competence} state="neutral" />
              </div>
            </div>
          </div>
        </NotificationCircle>
      </div>
    </UnstyledLink>
  );
};

const ChatView = () => {
  const now = new Date();
  const today = startOfDay(now);

  const { data: allEmployees } = useEmployees();
  const { data: shifts } = useScheduledShifts(today, today);
  const { data: centerShifts } = useScheduledCenterShifts(today, today);

  const employeesWithUnreadNotifications =
    useEmployeesWithUnreadNotifications(allEmployees);

  if (!allEmployees || !shifts || !centerShifts) {
    return <Loading message={t`Hämtar anställda`} />;
  }

  const centerShiftsNow = centerShifts.filter(
    (shift) => shift.startDateTime <= now && shift.endDateTime >= now,
  );

  const ambulatingEmployees = allEmployees
    .filter((employee) =>
      shifts.some(
        (shift) =>
          shift.startDateTime <= now &&
          shift.endDateTime >= now &&
          shift.employee?.id === employee.id,
      ),
    )
    .filter(
      (employee) =>
        !centerShiftsNow.some(
          (centerShift) => centerShift.employee?.id === employee.id,
        ),
    )
    .filter((employee) => !employeesWithUnreadNotifications.includes(employee))
    .sort(sortOnFirstName);

  const restOfEmployees = allEmployees
    .filter(
      (employee) =>
        !ambulatingEmployees.some(
          (ambulatingEmployee) => ambulatingEmployee.id === employee.id,
        ),
    )
    .filter((employee) => !employeesWithUnreadNotifications.includes(employee))
    .sort(sortOnFirstName);

  return (
    <div className={styles.chat}>
      <div className={styles.chatLists}>
        <section>
          <Heading level="h2">
            <Trans>Olästa</Trans>
          </Heading>
          <ul className={styles.chatList}>
            {employeesWithUnreadNotifications.length > 0 ? (
              employeesWithUnreadNotifications.map((employee) => (
                <li key={employee.id}>
                  <ChatListItemLink employee={employee} />
                </li>
              ))
            ) : (
              <NoResults message={t`Inga olästa meddelanden`} />
            )}
          </ul>
        </section>
        <section>
          <Heading level="h2">
            <Trans>Ambulerande</Trans>
          </Heading>
          <ul className={styles.chatList}>
            {ambulatingEmployees.map((employee) => (
              <li key={employee.id}>
                <ChatListItemLink employee={employee} />
              </li>
            ))}
          </ul>
        </section>
        <section>
          <Heading level="h2">
            <Trans>Övriga</Trans>
          </Heading>
          <ul className={styles.chatList}>
            {restOfEmployees.map((employee) => (
              <li key={employee.id}>
                <ChatListItemLink employee={employee} size="compact" />
              </li>
            ))}
          </ul>
        </section>
      </div>
      <Outlet />
    </div>
  );
};

export default ChatView;
