import {
  ANY_TIME_OF_DAY,
  activityKeys,
  rescheduleActivityOccurrence,
} from "@/api/Activities";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { FilledButton } from "@components/Button/Button";
import Form from "@/components/Form/Form";
import InputField from "@/components/InputField/InputField";
import { Loading } from "@components/Loading/Loading";
import { deducedError } from "@/Utils/ErrorUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { format } from "@models/date-and-time";
import RadioButtons from "@/components/RadioButton/RadioButtons";
import RadioButton from "@/components/RadioButton/RadioButton";
import type { ITimeOfDay } from "@models/activities";
import { timeOfDayDictionary, timeOfDaySchema } from "@models/activities";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";

type IEditTimeFields = {
  date: string;
  timeCategory: string;
  time: string;
};

export const EditTime = ({
  currentDateTime,
  currentTimeOfDay,
  onSuccess,
  activityId,
  occurrenceId,
}: {
  currentDateTime: Date;
  currentTimeOfDay: ITimeOfDay;
  onSuccess: () => void;
  activityId: string;
  occurrenceId: string;
}) => {
  const { _ } = useLingui();
  const methods = useForm<IEditTimeFields>({
    defaultValues: {
      date: format(currentDateTime, "yyyy-MM-dd"),
      timeCategory: currentTimeOfDay,
      time:
        currentTimeOfDay === timeOfDaySchema.Values.Any
          ? ""
          : i18n.date(currentDateTime, { timeStyle: "short" }),
    },
  });

  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    watch,
  } = methods;

  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (formData: IEditTimeFields) => {
      return rescheduleActivityOccurrence(activityId, occurrenceId, {
        date: formData.date,
        time:
          formData.timeCategory === timeOfDaySchema.Values.Any
            ? ANY_TIME_OF_DAY
            : formData.time,
      });
    },
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      onSuccess();
      queryClient.invalidateQueries({ queryKey: activityKeys.all });
    },
  });

  const isAnyTimeOfDay = watch("timeCategory") === timeOfDaySchema.Values.Any;

  return (
    <Form
      onSubmit={handleSubmit((formData) => {
        mutate(formData);
      })}
    >
      {isPending || isSuccess ? (
        <Loading message={t`Ändrar tid`} />
      ) : (
        <>
          {errors.root?.server?.message && !isDirty ? (
            <ErrorMessage message={errors.root.server.message} />
          ) : null}
          <Form.Row type="flex">
            <InputField
              label={t`Datum`}
              errorMessage={errors.date?.message}
              type="date"
              {...register(`date`, {
                required: {
                  value: true,
                  message: t`Aktiviteten behöver ett datum`,
                },
                min: {
                  value: format(new Date(), "yyyy-MM-dd"),
                  message: t`Datumet behöver vara idag eller senare`,
                },
              })}
            />
          </Form.Row>
          <Form.Row>
            <RadioButtons
              legend={t`Tid på dagen`}
              errorMessage={errors.timeCategory?.message}
              orientation="horizontal"
            >
              <RadioButton
                label={{ text: _(timeOfDayDictionary.Any.short) }}
                visualStyle="framed"
                value={timeOfDaySchema.Values.Any}
                {...register(`timeCategory`)}
              />
              <RadioButton
                label={{ text: _(timeOfDayDictionary.Specific.short) }}
                visualStyle="framed"
                value={timeOfDaySchema.Values.Specific}
                disabled={currentTimeOfDay === timeOfDaySchema.Values.Any}
                {...register(`timeCategory`, {
                  required: {
                    value: true,
                    message: t`Tid på dagen måste väljas`,
                  },
                })}
              />
            </RadioButtons>
          </Form.Row>
          {isAnyTimeOfDay ? (
            <></>
          ) : (
            <Form.Row>
              <InputField
                label={t`Tid`}
                errorMessage={errors.time?.message}
                type="time"
                {...register(`time`, {
                  required: {
                    value: true,
                    message: t`Aktiviteten behöver en tid`,
                  },
                  pattern: {
                    value: /^\d{1,2}:\d{2}$/,
                    message: t`Tid anges på formatet HH:mm`,
                  },
                })}
              />
            </Form.Row>
          )}
          {isDirty ? (
            <FilledButton type="submit">
              <Trans>Spara ändringar</Trans>
            </FilledButton>
          ) : (
            <></>
          )}
        </>
      )}
    </Form>
  );
};
