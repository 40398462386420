import { useSearchParams } from "react-router-dom";
import { format } from "@models/date-and-time";

export const useSelectedDate = () => {
  const now = new Date();
  return useSelectedDateNoDefault() ?? format(now, "yyyy-MM-dd");
};

export const useSelectedDateNoDefault = () => {
  const [searchParams] = useSearchParams();
  const dayInSearchParams = searchParams.get("day");

  if (dayInSearchParams) {
    return dayInSearchParams;
  }

  return undefined;
};
