import { z } from "zod";

const fiveDigitPostalCodeSchema = z.string().regex(/^\d{5}$/);

export const formatPostalCodeWithSpace = (postalCode: string) => {
  if (fiveDigitPostalCodeSchema.safeParse(postalCode).success) {
    return `${postalCode.substring(0, 3)} ${postalCode.substring(3)}`;
  }
  return postalCode;
};
