import HealthParametersIcon from "@components/icons/HealthParametersIcon";
import IconWithIndicator from "../IconWithIndicator";

type UnhandledMeasurementsIndicatorProps = {
  hasUnhandledMeasurements: boolean;
  passiveState?: "hidden" | "visible";
};

const UnhandledMeasurementsIndicator = ({
  hasUnhandledMeasurements,
  passiveState = "hidden",
}: UnhandledMeasurementsIndicatorProps) => {
  return (
    <IconWithIndicator
      icon={<HealthParametersIcon />}
      indicatorIsActive={hasUnhandledMeasurements}
      passiveState={passiveState}
    />
  );
};

export default UnhandledMeasurementsIndicator;
