import CreatePatientNoteForm from "@/forms/PatientNoteForm/CreatePatientNoteForm";
import ConvertedPatientNoteForm from "@/forms/PatientNoteForm/ConvertedPatientNoteForm";
import styles from "./PatientNote.module.scss";
import { Loading } from "@components/Loading/Loading";
import { z } from "zod";
import { useParams } from "react-router-dom";
import type { IPatientNoteStatus } from "@models/patientNotes";
import {
  patientNoteStates,
  patientNoteStatusSchema,
} from "@models/patientNotes";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { usePatientNote } from "@/api/PatientNote";
import { t } from "@lingui/macro";

const PatientNote = () => {
  const [isEditingConvertedPatientNote, setIsEditingConvertedPatientNote] =
    useState(false);
  const [isMutatingCreatedPatientNote, setIsMutatingCreatedPatientNote] =
    useState(false);
  const [statusToShow, setStatusToShow] = useState<IPatientNoteStatus>(
    patientNoteStatusSchema.Values.accepted,
  );

  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());

  const {
    data: patientNoteSucceeded,
    isPending: isPendingSucceeded,
    isError: isErrorSucceeded,
    error: errorSucceeded,
  } = usePatientNote(patientId, patientNoteStatusSchema.Values.succeeded);

  const {
    data: patientNoteAccepted,
    isPending: isPendingAccepted,
    isError: isErrorAccepted,
    error: errorAccepted,
  } = usePatientNote(patientId, patientNoteStatusSchema.Values.accepted);

  if (isErrorSucceeded || isErrorAccepted) {
    Sentry.captureException(errorSucceeded ? errorSucceeded : errorAccepted);
    return (
      <ErrorMessage
        message={t`Kunde inte ladda patientanteckning`}
        padding={24}
      />
    );
  }

  if (isPendingSucceeded || isPendingAccepted) {
    return <Loading message={t`Laddar patientanteckning`} padding={24} />;
  }

  const handleCreatedPatientNoteState = (noteState: string) => {
    setIsEditingConvertedPatientNote(noteState === patientNoteStates.EDITED);
    setIsMutatingCreatedPatientNote(noteState === patientNoteStates.MUTATED);

    if (noteState === patientNoteStates.SUBMITTED) {
      setStatusToShow(patientNoteStatusSchema.Values.succeeded);
    }
  };

  const handleConvertedPatientNoteState = (noteState: string) => {
    if (noteState === patientNoteStates.SUBMITTED) {
      setStatusToShow(patientNoteStatusSchema.Values.accepted);
    }
  };

  const patientNote =
    statusToShow === patientNoteStatusSchema.Values.accepted
      ? patientNoteAccepted
      : patientNoteSucceeded;

  const isPatientNoteConverted =
    (statusToShow === patientNoteStatusSchema.Values.accepted &&
      patientNote?.convertedText) ||
    (statusToShow === patientNoteStatusSchema.Values.succeeded && patientNote);

  return (
    <div className={styles.container}>
      <section>
        <CreatePatientNoteForm
          patientNote={patientNote}
          createdPatientNoteState={handleCreatedPatientNoteState}
        />
      </section>
      {isMutatingCreatedPatientNote ? (
        <Loading message={t`Laddar förenklad patientanteckning`} padding={24} />
      ) : (
        isPatientNoteConverted &&
        !isEditingConvertedPatientNote && (
          <section>
            <ConvertedPatientNoteForm
              patientNote={patientNote}
              convertedPatientNoteState={handleConvertedPatientNoteState}
              isLoadingConvertedNote={isMutatingCreatedPatientNote}
            />
          </section>
        )
      )}
    </div>
  );
};

export { PatientNote };
