import { useEffect } from "react";
import * as Sentry from "@sentry/react";

export const useAppVisibilityBreadcrumbs = () => {
  // Essential for tablet and mobile devices since it detects screen lock and navigation to other apps
  useEffect(() => {
    const recordVisibilityChangeAsBreadcrumb = () => {
      Sentry.addBreadcrumb({
        category: "visibilitychange",
        message:
          document.visibilityState === "visible"
            ? "App visible (Screen unlocked / navigated to the app (mobile) / unminimized the app (desktop))"
            : "App hidden (Screen locked / navigated away from the app (mobile) / minimized the app (desktop))",
      });
    };

    window.addEventListener(
      "visibilitychange",
      recordVisibilityChangeAsBreadcrumb,
    );
    return () => {
      window.removeEventListener(
        "visibilitychange",
        recordVisibilityChangeAsBreadcrumb,
      );
    };
  }, []);

  // Essential for desktop devices since it detects if the user is currently active on the page
  useEffect(() => {
    const recordAppFocus = () => {
      Sentry.addBreadcrumb({
        category: "focus",
        message: "App window focused",
      });
    };
    const recordAppBlur = () => {
      Sentry.addBreadcrumb({
        category: "blur",
        message: "App window blurred",
      });
    };

    window.addEventListener("focus", recordAppFocus);
    window.addEventListener("blur", recordAppBlur);
    return () => {
      window.removeEventListener("focus", recordAppFocus);
      window.removeEventListener("blur", recordAppBlur);
    };
  }, []);
};
