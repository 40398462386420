import { useFormContext } from "react-hook-form";
import type { IAddActivityFormData } from "@/forms/AddActivityForm/AddActivityForm";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import Checkbox from "@/components/Checkbox/Checkbox";
import {
  measurementsDictionary,
  orderedMeasurementsList,
} from "@models/activities";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";

export const MeasurementsPicker = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<IAddActivityFormData>();
  const { _ } = useLingui();
  return (
    <Checkboxes
      legend={t`Värden`}
      errorMessage={errors.measurements?.message}
      orientation="horizontal"
    >
      {orderedMeasurementsList.map((measurement) => {
        return (
          <Checkbox
            key={measurement}
            label={{ text: _(measurementsDictionary[measurement]) }}
            {...register(`measurements`, {
              required: {
                value: true,
                message: t`Minst ett mätvärde behöver anges`,
              },
            })}
            value={measurement}
            visualStyle="framed"
          />
        );
      })}
    </Checkboxes>
  );
};
