/**
 * Adds hours and minutes to a 24-hour time string
 * CAUTION! Does not account for time zones or daylight savings time!
 * @param baseTime The base time to add hours and minutes to, format "HH:MM"
 * @param timeToAdd The amount of hours and minutes to add, format "HH:MM:SS"
 * @returns A new time string with the added hours and minutes, format "HH:MM"
 */
export function addTime(baseTime: string, timeToAdd: string): string {
  const [baseHoursStr, baseMinutesStr] = baseTime.split(":");
  if (!baseHoursStr || !baseMinutesStr) {
    throw new Error("Base time must be in format HH:mm");
  }

  const baseHours = parseInt(baseHoursStr, 10);
  const baseMinutes = parseInt(baseMinutesStr, 10) || 0; // Default to 0 if no minutes are provided

  const [hoursToAddStr, minutesToAddStr] = timeToAdd.split(":");
  if (!hoursToAddStr || !minutesToAddStr) {
    throw new Error("Time to add must be in format HH:mm");
  }
  const hoursToAdd = parseInt(hoursToAddStr, 10);
  const minutesToAdd = parseInt(minutesToAddStr, 10) || 0; // Default to 0 if no minutes are provided

  const totalMinutes =
    baseHours * 60 + baseMinutes + hoursToAdd * 60 + minutesToAdd;
  const newHours = Math.floor(totalMinutes / 60) % 24; // Ensure it stays within 24-hour range
  const newMinutes = totalMinutes % 60;

  const formattedHours = newHours.toString().padStart(2, "0");
  const formattedMinutes = newMinutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
}
