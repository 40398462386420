import { Link } from "@components/Link/Link";
import type { IListPatient } from "@/api/Patients";
import { useCenterBaseUrl } from "@/Utils/useCenterBaseUrl";
import { patientStatusDictionary, patientStatusSchema } from "@models/patients";
import { useLingui } from "@lingui/react";

export const NameCell = ({ patient }: { patient: IListPatient }) => {
  const { _ } = useLingui();
  const centerBaseUrl = useCenterBaseUrl();
  if (patient.status === patientStatusSchema.Values.deleted) {
    return _(patientStatusDictionary.deleted.singular);
  }
  return (
    <Link to={`${centerBaseUrl}/patients/${patient.id}`} weight="regular">
      {patient.name}
    </Link>
  );
};

export default NameCell;
