import * as React from "react";
const SvgBulletList = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "-1.5 -1.5 27 27", height: "1em", width: "1em", ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("circle", { cx: 3, cy: 3, r: 2.25, style: {
  fill: "none",
  stroke: "var(--icon-color, currentColor)",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 2
} }), /* @__PURE__ */ React.createElement("circle", { cx: 3, cy: 12, r: 2.25, style: {
  fill: "none",
  stroke: "var(--icon-color, currentColor)",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 2
} }), /* @__PURE__ */ React.createElement("circle", { cx: 3, cy: 21, r: 2.25, style: {
  fill: "none",
  stroke: "var(--icon-color, currentColor)",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 2
} }), /* @__PURE__ */ React.createElement("line", { x1: 8.25, y1: 3, x2: 23.25, y2: 3, style: {
  fill: "none",
  stroke: "var(--icon-color, currentColor)",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 2
} }), /* @__PURE__ */ React.createElement("line", { x1: 8.25, y1: 12, x2: 23.25, y2: 12, style: {
  fill: "none",
  stroke: "var(--icon-color, currentColor)",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 2
} }), /* @__PURE__ */ React.createElement("line", { x1: 8.25, y1: 21, x2: 23.25, y2: 21, style: {
  fill: "none",
  stroke: "var(--icon-color, currentColor)",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 2
} })));
export default SvgBulletList;
