import styles from "./StatusBar.module.scss";

export const StatusBar = ({ children }: { children: string | JSX.Element }) => {
  return (
    <div className={styles.statusBar}>
      <span className={styles.pulsingCircle}></span>
      {children}
    </div>
  );
};
